import React, { PropsWithChildren } from 'react';

const OpacityButton = (
	props: PropsWithChildren<{ className?: string; onClick: () => void }>
) => {
	return (
		<button
			{...props}
			className={`hover:cursor-pointer hover:opacity-70 ${props.className}`}
		>
			{props.children}
		</button>
	);
};

export default OpacityButton;
