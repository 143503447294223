'use client';

import Link from 'next/link';
import { usePathname } from 'next/navigation';
import React, { MouseEvent } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import Button from './Button';
import useSafePush from 'src/hooks/useSafePush';
import { Wallet } from '@drift-labs/icons';

const SecondaryConnectWalletButton = () => {
	const set = useDriftStore((s) => s.set);
	const pathname = usePathname();
	const safePush = useSafePush();

	const setPreviousRoute = (e: MouseEvent) => {
		e.stopPropagation();

		set((s) => {
			s.wallet.lastRouteBeforeConnect = pathname;
		});

		safePush('/onboarding/connect');
	};

	return (
		<div className="xs:py-8 sm:py-0">
			<Button.Ghost
				size="MEDIUM"
				className="flex items-center justify-center"
				onClick={setPreviousRoute}
			>
				<Link href="/onboarding/connect" className="flex items-center">
					<Wallet className="mr-2" color="var(--text-default)" size={18} />
					<span className="text-xs text-text-default">Connect</span>
				</Link>
			</Button.Ghost>
		</div>
	);
};

export default SecondaryConnectWalletButton;
