'use client';

import { ArrowRight, Info, Swap } from '@drift-labs/icons';
import {
	BN,
	BigNum,
	MarketInfo,
	RoutePlanStep,
	SpotMarketConfig,
} from '@drift-labs/sdk';
import {
	FC,
	ReactNode,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { MIN_LEFTOVER_SOL, STABLE_COIN_SYMBOLS } from 'src/constants/constants';
import { OrderedSpotMarkets } from 'src/environmentVariables/EnvironmentVariables';
import useSwapFormEngine from 'src/hooks/swap/useSwapFormEngine';
import useSwapMarginConfig from 'src/hooks/swap/useSwapMarginConfig';
import useSwapMarkets from 'src/hooks/swap/useSwapMarkets';
import useTokenList from 'src/hooks/swap/useTokenList';
import {
	useJupiterTokenPairData,
	useTokenPairData,
} from 'src/hooks/swap/useTokenPairData';
import useUpdateSwapParams from 'src/hooks/swap/useUpdateSwapParams';
import useDriftActions from 'src/hooks/useDriftActions';
import UI_UTILS from 'src/utils/uiUtils';
import { twMerge } from 'tailwind-merge';
import useCurrentSettings from '../../hooks/useCurrentSettings';
import Button from '../Button';
import CheckboxInput from '../CheckboxInput';
import Chevron from '../Icons/Chevron';
import Select from '../Inputs/Select';
import TextField from '../Inputs/TextField';
import Utility from '../Inputs/Utility';
import SkeletonValuePlaceholder from '../SkeletonValuePlaceholder/SkeletonValuePlaceholder';
import Slider from '../Slider';
import Text from '../Text/Text';
import InfoMessage from '../TradeForm/InfoMessage';
import SlippageTolerance from '../TradeForm/SlippageTolerance';
import { formatValue } from '../Utils/NumberDisplayV2';
import Tooltip from '../Tooltip/Tooltip';
import useMaxMarginRatioAndLeverage from 'src/hooks/useMaxMarginRatioAndLeverage';
import Switch from '../Switch';
import PoweredByJupiter from '../Utils/PoweredByJupiter';
import { MAX_SPOT_POSITIONS_ERROR_MESSAGE } from 'src/constants/markets';
import { Alert } from '../Alert';
import ArcLoadingSpinner from '../Icons/LoadingSpinner/ArcLoadingSpinner';
import SettingsSwitch from '../Settings/SettingsSwitch';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import SwapQuoteErrorDisplay from './SwapQuoteErrorDisplay';

interface LabelAndInputWithActionProps {
	id: string;
	label: string;
	value: string;
	onValueChange: (value: string) => void;
	actionComponent?: ReactNode;
	selectedMarket: SpotMarketConfig;
	onMarketChange: (market: SpotMarketConfig) => void;
	tokenUsdPrice: number;
	disabled?: boolean;
	options?: SpotMarketConfig[];
	infoText?: string;
}

export const LabelAndInputWithAction: FC<LabelAndInputWithActionProps> = ({
	id,
	label,
	value,
	onValueChange,
	actionComponent,
	selectedMarket,
	onMarketChange,
	tokenUsdPrice,
	disabled,
	options,
	infoText,
}) => {
	const collateralSelectorOptions = useMemo(
		() =>
			(options ?? OrderedSpotMarkets).map((market) => ({
				label: market.symbol,
				value: market.symbol,
			})),
		[options]
	);

	return (
		<div className="flex flex-col gap-1">
			<div className="flex items-center justify-between">
				<Text.INPUTLABEL01 className="text-text-label">
					{label}
				</Text.INPUTLABEL01>
				<div className="flex items-center">
					{actionComponent}
					{infoText && (
						<Tooltip content={infoText}>
							<Info
								color={'var(--text-secondary-button)'}
								className="mb-0.5 ml-0.5"
								size={12}
							/>
						</Tooltip>
					)}
				</div>
			</div>
			<div className="flex">
				<Select.CollateralSelector
					id={id}
					options={collateralSelectorOptions}
					selection={selectedMarket.symbol}
					onChange={(value) => {
						onMarketChange(
							OrderedSpotMarkets.find((market) => market.symbol === value)
						);
					}}
				/>
				<TextField.Default
					type="number"
					value={value}
					onChange={onValueChange}
					customRounding="0 0.125rem 0.125rem 0"
					depsForOnChange={[selectedMarket]}
					disabled={disabled}
				/>
			</div>
			<div className="w-full text-right">
				<Text.BODY3 className="text-text-label">
					~${(tokenUsdPrice * (+value || 0)).toFixed(2)}
				</Text.BODY3>
			</div>
		</div>
	);
};

export const RouteDetails = ({
	loading,
	routePlan,
}: {
	loading: boolean;
	routePlan: RoutePlanStep[];
}) => {
	const lpProtocols = Array.from(
		new Set(routePlan.map((step) => step.swapInfo.label))
	);

	return (
		<div className="flex flex-col w-full gap-1 text-text-default">
			<Text.BODY3 className="mr-1 text-text-label">Route</Text.BODY3>
			{loading ? (
				<div className="flex flex-row items-center gap-2">
					<ArcLoadingSpinner />{' '}
					<Text.BODY3 className="">Fetching swap quote...</Text.BODY3>
				</div>
			) : lpProtocols.length === 0 ? (
				<div className="min-h-[14px] w-full" />
			) : (
				<Text.BODY3>via {lpProtocols.join(', ')}</Text.BODY3>
			)}
		</div>
	);
};

export const RouteAndFees = ({
	marketInfos,
	loading,
}: {
	marketInfos: MarketInfo[];
	loading: boolean;
}) => {
	const tokenList = useTokenList();

	const [showRoutes, setShowRoutes] = useState(false);

	const renderRoutes = () => {
		if (!marketInfos?.length) {
			return (
				<Text.BODY3 className="text-text-default">No routes found!</Text.BODY3>
			);
		}

		return marketInfos.map((marketInfo, index) => {
			const inToken = tokenList.find(
				(token) => token.address === marketInfo.inputMint
			);
			const outToken = tokenList.find(
				(token) => token.address === marketInfo.outputMint
			);
			const feeToken = tokenList.find(
				(token) => token.address === marketInfo.lpFee.mint
			);

			const amountIn = BigNum.from(marketInfo.inAmount);
			amountIn.precision = new BN(inToken?.decimals ?? 6);
			const amountOut = BigNum.from(marketInfo.outAmount);
			amountOut.precision = new BN(outToken?.decimals ?? 6);
			const fee = BigNum.from(marketInfo.lpFee.amount);
			fee.precision = new BN(feeToken?.decimals ?? 6);

			return (
				<div className="flex flex-col gap-2" key={marketInfo.id}>
					{index === 0 && (
						<Text.BODY3 className="flex items-center gap-1 text-text-default">
							{formatValue({
								value: amountIn,
								isInvalid: false,
								displayType: 'asset',
								assetSymbol: inToken?.symbol,
							})}
							<img src={inToken?.logoURI} width={16} className="rounded-full" />
						</Text.BODY3>
					)}

					<span className="flex items-center gap-1 ml-2 text-text-label">
						<ArrowRight className="rotate-90" size={16} />
						<Text.MICRO1>
							{marketInfo.label} -{' '}
							{formatValue({
								value: fee,
								isInvalid: false,
								displayType: 'asset',
								assetSymbol: feeToken?.symbol,
							})}{' '}
							fee
						</Text.MICRO1>
					</span>

					<Text.BODY3 className="flex items-center gap-1 text-text-default">
						{formatValue({
							value: amountOut,
							isInvalid: false,
							displayType: 'asset',
							assetSymbol: outToken?.symbol,
						})}
						<img src={outToken?.logoURI} width={16} className="rounded-full" />
					</Text.BODY3>
				</div>
			);
		});
	};

	return (
		<>
			<div
				onClick={() => setShowRoutes(!showRoutes)}
				className="flex items-center cursor-pointer hover:opacity-70 w-fit"
			>
				<Text.BODY3 className="mr-1 text-text-label">Route + Fees</Text.BODY3>
				<Chevron
					direction={showRoutes ? 'up' : 'down'}
					colour={'var(--text-label)'}
				/>
			</div>
			<div
				className={twMerge(
					'flex flex-col w-full overflow-auto thin-scroll flex-grow gap-2 -mt-2',
					showRoutes ? 'max-h-[112px] min-h-[112px]' : 'max-h-0'
				)}
			>
				{loading ? (
					<SkeletonValuePlaceholder className="w-full h-[64px]" />
				) : (
					renderRoutes()
				)}
			</div>
		</>
	);
};

interface MarginAndLeverageSelectorProps {
	maxSwapSize: BigNum;
	swapFromNumericAmount: number;
	setSwapAmount: (amount: string) => void;
	currentLeverage: number;
	maxLeverageAllowed: number;
}

export const MarginAndLeverageSelector = ({
	swapFromNumericAmount,
	maxSwapSize,
	setSwapAmount,
	currentLeverage,
	maxLeverageAllowed,
}: MarginAndLeverageSelectorProps) => {
	const actions = useDriftActions();
	const [savedSettings, setSavedSettings] = useCurrentSettings();
	const { allowMargin, hasMarginEnabled, hasLeverageEnabled } =
		useSwapMarginConfig();

	const toggleSwapLeverage = () => {
		setSavedSettings({
			...savedSettings,
			enableSwapLeverage: !savedSettings.enableSwapLeverage,
		});
	};

	const leverageValue =
		maxSwapSize.toNum() === 0 || swapFromNumericAmount > maxSwapSize.toNum()
			? maxLeverageAllowed.toFixed(2)
			: (
					(swapFromNumericAmount / maxSwapSize.toNum()) *
						(maxLeverageAllowed - currentLeverage) +
					currentLeverage
			  ).toFixed(2) || '0.00';

	const openMarginSettings = () => {
		actions.showModal('showMarginModal');
	};

	const handleSliderValChange = (pct: number) => {
		if (pct === 100) {
			// we don't want to set to 2 decimal places at 100%
			setSwapAmount(maxSwapSize.toNum().toString());
			return;
		}

		// round to 2 decimal places for UI to look cleaner
		const amount = (maxSwapSize.toNum() * pct) / 100;
		setSwapAmount(amount.toFixed(2));
	};

	return (
		<div className="flex flex-col gap-4">
			<div className="flex items-center justify-between text-text-label">
				{hasMarginEnabled && (
					<CheckboxInput
						label={
							<Text.INPUTLABEL01 className="font-normal text-text-label">
								Leverage
							</Text.INPUTLABEL01>
						}
						checked={hasLeverageEnabled}
						onChange={toggleSwapLeverage}
						secondaryStyle
					/>
				)}
				<div
					className="flex gap-1 cursor-pointer text-text-label"
					onClick={openMarginSettings}
				>
					<Info className="w-4 h-4" />
					<Text.INPUTLABEL01>
						Margin {hasMarginEnabled ? 'Enabled' : 'Disabled'}
					</Text.INPUTLABEL01>
				</div>
			</div>
			{allowMargin && (
				<div>
					<Slider
						step={1}
						customButtonOptions={[
							{ val: 25 },
							{ val: 50 },
							{ val: 75 },
							{ val: 100 },
						]}
						type="leverage"
						disabled={false}
						onDrop={(val) => {
							handleSliderValChange(val);
						}}
						onMove={(val) => {
							handleSliderValChange(val);
						}}
						value={
							Math.abs((swapFromNumericAmount / maxSwapSize.toNum()) * 100) || 0
						}
						displayValue={leverageValue}
					/>
				</div>
			)}
		</div>
	);
};

const SwapDetailsRow = ({
	label,
	value,
	className,
	warn,
}: {
	label: string;
	value: string;
	className?: string;
	warn?: boolean;
}) => {
	return (
		<div className={twMerge('flex justify-between', className)}>
			<Text.BODY3 className="text-text-secondary">{label}</Text.BODY3>
			<Text.BODY3 className={warn ? 'text-warn-yellow' : 'text-text-default'}>
				{value}
			</Text.BODY3>
		</div>
	);
};

export const SwapDetails = ({
	amount,
	priceImpact,
	isExactIn,
	borrowAmount,
	borrowToBeRepaid,
	fromSymbol,
	toSymbol,
}: {
	amount: string;
	priceImpact: number;
	isExactIn: boolean;
	borrowAmount?: BigNum;
	borrowToBeRepaid?: BigNum;
	fromSymbol?: string;
	toSymbol?: string;
}) => {
	const [savedSettings, setSavedSettings] = useCurrentSettings();
	const directRouteSwaps = savedSettings.directRouteSwaps;

	const priceImpactString =
		priceImpact === 0
			? '0'
			: priceImpact > 0.001
			? `${(priceImpact * 100).toFixed(2)}%`
			: '< 0.1%';

	return (
		<div className="flex flex-col gap-2">
			<SwapDetailsRow
				label={isExactIn ? 'Minimum received' : 'Maximum sent'}
				value={amount}
			/>
			<SwapDetailsRow
				label="Price Impact"
				value={priceImpactString}
				warn={priceImpact > 0.01}
			/>
			<SwapDetailsRow
				label="Borrow Amount"
				value={
					borrowAmount?.gtZero() ? `${borrowAmount.print()} ${fromSymbol}` : '-'
				}
			/>
			{borrowToBeRepaid?.gtZero() && (
				<SwapDetailsRow
					label="Repay Borrow"
					value={`${borrowToBeRepaid.prettyPrint()} ${toSymbol}`}
				/>
			)}
			<div className="flex items-center justify-between">
				<Text.BODY3 className="flex items-center gap-1 text-text-secondary">
					<span>Use direct route</span>
					<Tooltip content="Direct route swaps have a higher chance of succeeding but may result in a less desired price.">
						<Info />
					</Tooltip>
				</Text.BODY3>
				<Switch
					checked={directRouteSwaps}
					onChange={() =>
						setSavedSettings({
							...savedSettings,
							directRouteSwaps: !directRouteSwaps,
						})
					}
				/>
			</div>
		</div>
	);
};

export const SwapPrice = ({
	inAmount,
	outAmount,
	inMarket,
	outMarket,
	isLoadingRoute,
	swapPrice,
}: {
	inAmount: BigNum;
	outAmount: BigNum;
	inMarket: SpotMarketConfig;
	outMarket: SpotMarketConfig;
	isLoadingRoute: boolean;
	swapPrice: number;
}) => {
	const [isSellPrice, setIsSellPrice] = useState(true);

	// default to buy price when selling stablecoins
	useEffect(() => {
		if (STABLE_COIN_SYMBOLS.includes(inMarket.symbol)) {
			setIsSellPrice(false);
		} else {
			setIsSellPrice(true);
		}
	}, [inMarket.symbol, outMarket.symbol]);

	const rightSidePrice = isSellPrice ? swapPrice : 1 / swapPrice;

	if (
		outAmount.eqZero() ||
		inAmount.eqZero() ||
		rightSidePrice === Infinity ||
		rightSidePrice === 0 ||
		isLoadingRoute
	)
		return <div className="h-4 min-h-[16px] w-full" />;

	return (
		<div className="flex items-center justify-between text-text-default">
			<Text.BODY3>
				1 {isSellPrice ? inMarket.symbol : outMarket.symbol} ≈{' '}
				{Number(
					rightSidePrice.toFixed(
						isSellPrice
							? outMarket.precisionExp.toNumber()
							: inMarket.precisionExp.toNumber()
					)
				)}{' '}
				{isSellPrice ? outMarket.symbol : inMarket.symbol}
			</Text.BODY3>
			<span
				onClick={() => setIsSellPrice(!isSellPrice)}
				className="flex items-center justify-center cursor-pointer"
			>
				<Swap />
			</span>
		</div>
	);
};

const SwapForm = () => {
	const updateSwapParams = useUpdateSwapParams();
	const solBalance = useDriftStore((s) => s.wallet.currentSolBalance);
	const { swapFromMarket, swapToMarket } = useSwapMarkets();
	const { fromTokenPrice, toTokenPrice } = useTokenPairData(
		swapFromMarket?.symbol,
		swapToMarket?.symbol
	);

	const tokenPairPrice = useJupiterTokenPairData(swapFromMarket, swapToMarket);
	const [savedSettings, setSavedSettings] = useCurrentSettings();
	const directRouteSwaps = savedSettings.directRouteSwaps;

	const {
		enableVersionedTx,
		enableVersionedTransaction,
		swapMode,
		setSwapMode,
		swapFromAmount,
		setSwapFromAmount,
		swapToAmount,
		setSwapToAmount,
		inputNumericAmount,
		quoteDetails,
		swapPrice,
		resetForm,
		handleSwap,
		isLoading,
		setIsLoading,
		isButtonDisabled,
		maxLeverageAllowed,
		maxSwapSize,
		currentLeverage,
		isInsufficientBalance,
		calcOtherAmountAtMaxSlippage,
		isGeoblocked,
		borrowAmount,
		borrowToBeRepaid,
		isApprovingTxn,
		hasMaxSpotBalance,
	} = useSwapFormEngine(swapFromMarket, swapToMarket);

	const maxMarginRatio = useMaxMarginRatioAndLeverage()[0];

	const userHasSelfImposedMax = !!maxMarginRatio && maxMarginRatio !== 0;
	const showTradeConfirmation = savedSettings.showTradeConfirmation;

	const isExactIn = swapMode === 'ExactIn';
	const hasEnoughSolInWallet = solBalance.gte(MIN_LEFTOVER_SOL);

	const handleFromMarketChange = (market: SpotMarketConfig) => {
		if (swapToMarket.symbol === market.symbol) {
			reverseTokenPair();
		} else {
			updateSwapParams(market.symbol, swapToMarket.symbol);
		}
	};

	const handleToMarketChange = (market: SpotMarketConfig) => {
		if (swapFromMarket.symbol === market.symbol) {
			reverseTokenPair();
		} else {
			updateSwapParams(swapFromMarket.symbol, market.symbol);
		}
	};

	const handleToggleShowConfirmation = useCallback(() => {
		setSavedSettings({
			showTradeConfirmation: !showTradeConfirmation,
		});
	}, [showTradeConfirmation]);

	const reverseTokenPair = () => {
		if (isLoading) return;
		const _swapToAmount = swapToAmount;
		const _swapFromAmount = swapFromAmount;
		resetForm();
		updateSwapParams(swapToMarket.symbol, swapFromMarket.symbol);
		setTimeout(() => {
			setIsLoading(true);
			if (swapMode === 'ExactOut') {
				setSwapMode('ExactIn');
				setSwapFromAmount(_swapToAmount);
			} else {
				setSwapMode('ExactOut');
				setSwapToAmount(_swapFromAmount);
			}
		}, 1);
	};

	const handleSwapToAmountUserChange = (val: string) => {
		setSwapMode('ExactOut');
		const truncatedInput = UI_UTILS.truncateInputToPrecision(
			val,
			swapToMarket.precisionExp
		);
		setSwapToAmount(truncatedInput);
	};

	const handleSwapFromAmountUserChange = (val: string) => {
		setSwapMode('ExactIn');
		const truncatedInput = UI_UTILS.truncateInputToPrecision(
			val,
			swapFromMarket.precisionExp
		);
		setSwapFromAmount(truncatedInput);
	};

	const handleTryExactIn = () => {
		if (swapToAmount && tokenPairPrice) {
			const estimatedSwapFromAmount =
				(Number(swapToAmount) / tokenPairPrice) * 1.001;

			handleSwapFromAmountUserChange(`${estimatedSwapFromAmount}`);
		}
	};

	return (
		<div className="flex flex-col w-full h-full gap-3 p-3 border rounded bg-container-bg border-container-border">
			<div className="flex flex-col gap-4">
				<LabelAndInputWithAction
					id="swapFromMarketSelector"
					label="Pay"
					value={swapFromAmount}
					onValueChange={handleSwapFromAmountUserChange}
					actionComponent={
						<Text.BODY3
							className="flex justify-center px-1 rounded-sm bg-button-secondary-bg item-center text-text-secondary-button py-[2px] cursor-pointer"
							onClick={() =>
								handleSwapFromAmountUserChange(maxSwapSize.print())
							}
						>
							Max: {maxSwapSize.toFixed(2)} {swapFromMarket.symbol}
						</Text.BODY3>
					}
					selectedMarket={swapFromMarket}
					onMarketChange={handleFromMarketChange}
					tokenUsdPrice={fromTokenPrice}
					infoText={
						userHasSelfImposedMax
							? `Your max amount may be less than is shown here due to self imposed account leverage restrictions. You can adjust this in settings.`
							: undefined
					}
				/>

				<div className="relative w-full">
					<div className="w-full h-[1px] bg-container-border" />
					<span
						className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center"
						onClick={reverseTokenPair}
					>
						<Swap
							className="p-[2px] rotate-90 bg-button-secondary-bg cursor-pointer rounded-sm"
							color={'var(--text-secondary-button)'}
							size={24}
						/>
					</span>
				</div>

				<LabelAndInputWithAction
					id="swapToMarketSelector"
					label="Receive"
					value={swapToAmount}
					onValueChange={handleSwapToAmountUserChange}
					selectedMarket={swapToMarket}
					onMarketChange={handleToMarketChange}
					tokenUsdPrice={toTokenPrice}
				/>

				<SwapPrice
					inAmount={BigNum.fromPrint(
						swapFromAmount,
						swapFromMarket.precisionExp
					)}
					outAmount={BigNum.fromPrint(swapToAmount, swapToMarket.precisionExp)}
					inMarket={swapFromMarket}
					outMarket={swapToMarket}
					isLoadingRoute={isLoading}
					swapPrice={swapPrice}
				/>

				<MarginAndLeverageSelector
					swapFromNumericAmount={inputNumericAmount}
					maxSwapSize={maxSwapSize}
					setSwapAmount={handleSwapFromAmountUserChange}
					currentLeverage={currentLeverage}
					maxLeverageAllowed={maxLeverageAllowed}
				/>

				<div className="mt-6 sm:mt-0">
					<SlippageTolerance />
				</div>

				{quoteDetails?.error && !isLoading ? (
					<SwapQuoteErrorDisplay
						quoteDetails={quoteDetails}
						swapMode={swapMode}
						useDirectRoute={directRouteSwaps}
						onTryExactIn={handleTryExactIn}
					/>
				) : (
					<RouteDetails
						loading={isLoading}
						routePlan={quoteDetails?.routePlan ?? []}
					/>
				)}

				{/* <RouteAndFees
					marketInfos={routeDetails?.marketInfos ?? []}
					loading={isLoading}
				/> */}
			</div>

			{!isExactIn && (
				<Alert
					message="You are using ExactOut mode."
					type="warning"
					description="ExactOut swap has fewer liquidity routes and may cost more. It
					guarantees the amount you receive, but the amount you pay can vary."
				/>
			)}

			<Utility.VERTDIVIDER />

			<SwapDetails
				amount={calcOtherAmountAtMaxSlippage()}
				priceImpact={+quoteDetails?.priceImpactPct || 0}
				isExactIn={isExactIn}
				borrowAmount={borrowAmount}
				fromSymbol={swapFromMarket.symbol}
				toSymbol={swapToMarket.symbol}
				borrowToBeRepaid={borrowToBeRepaid}
			/>

			{isGeoblocked && (
				<InfoMessage
					type="error"
					message={
						<div>
							You are not allowed to use Drift from a restricted territory.
						</div>
					}
				/>
			)}

			{!enableVersionedTx && (
				<InfoMessage
					type="warn"
					message={
						<div className="flex flex-col gap-1 text-text-default">
							<div>You need to enable Versioned Transactions to use Swaps.</div>
							<div>
								<Button.Secondary
									size="SMALL"
									onClick={enableVersionedTransaction}
								>
									Enable
								</Button.Secondary>
							</div>
						</div>
					}
				/>
			)}

			{hasMaxSpotBalance && (
				<InfoMessage
					type="error"
					messageTitle={MAX_SPOT_POSITIONS_ERROR_MESSAGE.title}
					message={MAX_SPOT_POSITIONS_ERROR_MESSAGE.message}
				/>
			)}

			<div className="flex items-left">
				<SettingsSwitch
					label="Show Confirmation"
					checked={showTradeConfirmation}
					onChange={handleToggleShowConfirmation}
					spacedOut
					includeLineSpace={false}
					className="w-full [&>span]:text-[12px]"
				/>
			</div>

			<Button.Primary
				size="LARGE"
				className="my-1"
				onClick={handleSwap}
				disabled={isButtonDisabled || !hasEnoughSolInWallet}
			>
				{!quoteDetails || !!quoteDetails.error
					? 'No routes found'
					: !hasEnoughSolInWallet
					? 'Not Enough SOL In Wallet'
					: isInsufficientBalance
					? 'Insufficient Balance'
					: isLoading
					? 'Loading'
					: isApprovingTxn
					? 'Swapping...'
					: 'Swap'}
			</Button.Primary>

			<PoweredByJupiter />
		</div>
	);
};

export default SwapForm;
