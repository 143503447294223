'use client';

import { MarketId } from '@drift/common';
import useDriftStore from '../stores/DriftStore/useDriftStore';

const useBorrowLendDataForMarket = (market: MarketId) => {
	if (!market.isSpot) {
		throw 'Must use a SPOT market in useBorrowLendDataForMarket';
	}

	const borrowLendData = useDriftStore((s) => s.borrowLendData);

	const targetMarketBorrowLendData = borrowLendData.find(
		(borrowLend) => borrowLend.bankConfig.marketIndex === market.marketIndex
	);

	return targetMarketBorrowLendData;
};

export default useBorrowLendDataForMarket;
