'use client';

import useDriftAccountStore from 'src/stores/useDriftAccountsStore';

const useUserAccountIsReady = (userKey?: string) => {
	const userAccountIsReady = useDriftAccountStore((s) => {
		// Doing it this way for a perf improvement
		// Avoids rerenders when using this hook unless these specific values changed
		return (
			s.accounts[userKey ?? s.currentUserKey]?.client &&
			s.accounts[userKey ?? s.currentUserKey]?.isSubscribed &&
			s.accounts[userKey ?? s.currentUserKey]?.client?.isSubscribed
		);
	});

	return userAccountIsReady;
};

export default useUserAccountIsReady;
