import styled from '@emotion/styled';
import React, { PropsWithChildren } from 'react';

const GradientBox = styled.div`
	background: var(--app-gradient);
`;

const GradientBackgroundBox = (
	props: PropsWithChildren<{ className?: string }>
) => {
	return (
		<GradientBox className={props.className}>{props.children}</GradientBox>
	);
};

export default GradientBackgroundBox;
