import { BigNum } from '@drift-labs/sdk';
import React from 'react';
import { compareDriftProps } from 'src/utils/compareProps';
import Text from './Text/Text';
import { twMerge } from 'tailwind-merge';

export const Arrow = () => {
	return (
		<svg viewBox="0 1 13 7" fill="none" style={{ height: '10px' }}>
			<path
				d="M9.035 7.233V4.893H0.663V3.307H9.035V0.967L12.389 4.1L9.035 7.233Z"
				fill="currentColor"
			/>
		</svg>
	);
};

const ValueChangeDisplay = ({
	previousValue,
	afterValue,
	leftSymbol = '',
	rightSymbol = '',
	reverseColours = false,
	forceWhite = false,
	previousValuePrint,
	afterValuePrint,
	overrideNoValueChange,
	className,
}: {
	previousValue: number | BigNum;
	afterValue: number | BigNum;
	leftSymbol?: string;
	rightSymbol?: string;
	previousValuePrint: string;
	afterValuePrint: string;
	reverseColours?: boolean;
	forceWhite?: boolean;
	overrideNoValueChange?: boolean;
	className?: string;
}) => {
	const isEqual =
		typeof afterValue === 'number'
			? afterValue === previousValue
			: afterValue.eq(previousValue as BigNum);

	const isLessThan =
		typeof afterValue === 'number'
			? afterValue < (previousValue as number)
			: afterValue.lt(previousValue as BigNum);

	const arrowColor = forceWhite
		? 'text-text-emphasis'
		: isLessThan
		? !reverseColours
			? 'text-negative-red'
			: 'text-positive-green'
		: !reverseColours
		? 'text-positive-green'
		: 'text-negative-red';

	return (
		<div
			className={twMerge(
				'flex flex-wrap items-center justify-end space-x-1 font-numeral',
				className
			)}
		>
			<Text.BODY3 className="whitespace-nowrap">
				{`${leftSymbol}${previousValuePrint}${rightSymbol}`}
			</Text.BODY3>
			{!isEqual && !overrideNoValueChange && (
				<div className={arrowColor}>
					<Arrow />
				</div>
			)}
			{!isEqual && !overrideNoValueChange && (
				<Text.BODY3 className="whitespace-nowrap">{`${leftSymbol}${afterValuePrint}${rightSymbol}`}</Text.BODY3>
			)}
		</div>
	);
};

export default React.memo(ValueChangeDisplay, compareDriftProps);
