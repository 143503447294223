'use client';

import React, { useEffect, useState } from 'react';
import AnimatedToggleDrawer from 'src/components/AnimatedDrawer/AnimatedToggleDrawer';
import Button from 'src/components/Button';
import Chevron from 'src/components/Icons/Chevron';
import TextField from 'src/components/Inputs/TextField';
import Text from 'src/components/Text/Text';
import OpacityButton from 'src/components/Utils/OpacityButton';
import { useLocalStorageStringState } from 'src/hooks/useLocalStorageState';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import TradeFormInputLabel from './TradeFormInputLabel';
import { twMerge } from 'tailwind-merge';

type SlippageProps = {
	className?: string;
	textClassName?: string;
	optionsClassName?: string;
	selectedOptionClassName?: string;
	hideToggle?: boolean;
	customAtEnd?: boolean;
	disabled?: boolean;
	//optionally pass in the current values
	slippageTolerance?: string;
	allowInfSlippage?: boolean;
	// if changed directly from trade form user needs to sign (if enabled), if changed in settings modal only sign after they click save
	updateHandler?: (value) => void;
	disableGradientBorder?: boolean;
};

const presetOptions = [0.1, 0.5, 1];
const SlippageTolerance = ({
	className,
	textClassName,
	optionsClassName,
	hideToggle = false,
	customAtEnd = false,
	disabled = false,
	slippageTolerance,
	allowInfSlippage,
	updateHandler,
	disableGradientBorder,
	selectedOptionClassName,
}: SlippageProps) => {
	const setState = useDriftStore((s) => s.set);
	const tradeFormSettings = useDriftStore((s) => s.tradeForm);

	// use passed value if coming from settings form, tradeform value if not
	const currentSlippageTolerance =
		slippageTolerance ?? tradeFormSettings?.slippageTolerance;
	const currentAllowInfSlippage =
		allowInfSlippage != undefined
			? allowInfSlippage
			: tradeFormSettings.allowInfSlippage;

	const [slippageToleranceString, setSlippageToleranceString] = useState(
		currentSlippageTolerance?.toString() ?? ''
	);

	const [allowInfSlippageCurrent, setAllowInfSlippageCurrent] = useState(
		currentAllowInfSlippage
	);

	const setSlippageToleranceInf = () => {
		setAllowInfSlippageCurrent(true);

		if (updateHandler) {
			updateHandler('inf');
			return;
		}

		setState((s) => {
			s.tradeForm.slippageTolerance = undefined;
			s.tradeForm.allowInfSlippage = true;
		});
	};

	const setSlippageTolerance = (newTolerance: number) => {
		if (updateHandler) {
			updateHandler(newTolerance.toString());
			return;
		}

		setState((s) => {
			s.tradeForm.slippageTolerance = newTolerance;
			s.tradeForm.allowInfSlippage = false;
		});
	};

	const handleSlippageChange = (newSlippage: string) => {
		if (newSlippage === '') {
			setSlippageToleranceInf();
			return;
		}

		setAllowInfSlippageCurrent(false);

		try {
			let value = parseFloat(newSlippage);

			if (value > 99) {
				value = 99;
				newSlippage = '99';
			}

			setSlippageTolerance(value);
		} catch (e) {
			// value is not a float .. should be ok
		}

		setSlippageToleranceString(newSlippage);
	};

	const [expanded, setExpanded] = useLocalStorageStringState(
		'slippageToleranceExpanded',
		'false'
	);
	const isExpanded = expanded === 'true';

	const toggleExpanded = () => {
		if (isExpanded) setExpanded('false');
		else setExpanded('true');
	};

	/* Keep values updated if they get changed from elsewhere in the app */
	useEffect(() => {
		if (currentSlippageTolerance?.toString() !== slippageToleranceString)
			setSlippageToleranceString(currentSlippageTolerance?.toString());

		if (currentAllowInfSlippage !== allowInfSlippageCurrent)
			setAllowInfSlippageCurrent(currentAllowInfSlippage);
	}, [currentSlippageTolerance, currentAllowInfSlippage]);

	const currentSlippageString = slippageToleranceString;

	return (
		<div>
			{!hideToggle && (
				<TradeFormInputLabel
					allowPointer
					className={twMerge('mb-0', textClassName)}
				>
					<OpacityButton
						className="flex items-center space-x-1"
						onClick={toggleExpanded}
					>
						<Text.BODY3>
							Slippage Tolerance (
							{allowInfSlippageCurrent
								? 'infinite'
								: `${currentSlippageTolerance}%`}
							)
						</Text.BODY3>
						<Chevron
							colour="var(--text-label)"
							direction={isExpanded ? 'up' : 'down'}
						/>
					</OpacityButton>
				</TradeFormInputLabel>
			)}

			<AnimatedToggleDrawer
				expanded={hideToggle ? true : isExpanded}
				className={isExpanded && 'mt-1'}
			>
				<div className={`flex justify-between space-x-2 ${className ?? ``}`}>
					{!customAtEnd && (
						<span className="w-1/3">
							<TextField.Default
								placeholder="Custom"
								type="number"
								stepAmount={0.01}
								onChange={handleSlippageChange}
								value={currentSlippageString as string}
								suffix={`%`}
								highlighted={
									!presetOptions.find(
										(presetValue) =>
											presetValue === Number(currentSlippageString)
									) && !allowInfSlippageCurrent
								}
								disabled={disabled}
								disableGradientBorder={disableGradientBorder}
								className={twMerge(
									optionsClassName,
									!presetOptions.find(
										(presetValue) =>
											presetValue === Number(currentSlippageString)
									) &&
										!allowInfSlippageCurrent &&
										selectedOptionClassName
								)}
							/>
						</span>
					)}

					<div className="flex justify-between flex-grow space-x-2">
						<>
							{presetOptions.map((option) => (
								<Button.Secondary
									key={option}
									size="SMALL"
									roundedGradientBorder={!disableGradientBorder}
									selected={option === Number(currentSlippageString)}
									className={twMerge(
										'flex-grow px-2 py-2 text-xs font-numeral',
										optionsClassName,
										option === Number(currentSlippageString) &&
											selectedOptionClassName
									)}
									style={{ width: '0' }}
									onClick={() => {
										handleSlippageChange(option.toString());
									}}
									disabled={disabled}
								>
									{option}%
								</Button.Secondary>
							))}
							<Button.Secondary
								key={'inf'}
								size="SMALL"
								roundedGradientBorder={!disableGradientBorder}
								selected={allowInfSlippageCurrent}
								className={twMerge(
									'flex-grow text-xl font-numeral',
									optionsClassName,
									allowInfSlippageCurrent && selectedOptionClassName
								)}
								style={{ width: '0', lineHeight: '10px' }}
								onClick={() => {
									handleSlippageChange('');
								}}
								disabled={disabled}
							>
								∞
							</Button.Secondary>
						</>
					</div>

					{customAtEnd && (
						<span className="w-1/3">
							<TextField.Default
								placeholder="Custom"
								type="number"
								stepAmount={0.01}
								onChange={handleSlippageChange}
								value={currentSlippageString}
								suffix={`%`}
								highlighted={
									!presetOptions.find(
										(presetValue) =>
											presetValue === Number(currentSlippageString)
									) && !allowInfSlippageCurrent
								}
								disabled={disabled}
							/>
						</span>
					)}
				</div>
			</AnimatedToggleDrawer>
		</div>
	);
};

export default React.memo(SlippageTolerance);
