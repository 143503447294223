'use client';

import {
	BigNum,
	PRICE_PRECISION_EXP,
	MarketType,
	SpotBalanceType,
	ZERO,
	SpotMarketConfig,
	convertToNumber,
	PositionDirection,
	TEN_THOUSAND,
	SpotMarketAccount,
} from '@drift-labs/sdk';
import {
	COMMON_UI_UTILS,
	ENUM_UTILS,
	UIOrderType,
	UI_ORDER_TYPES_LIST,
	UI_ORDER_TYPES,
	UIMarket,
	MarketId,
} from '@drift/common';
import React, { CSSProperties, memo, useEffect, useRef, useState } from 'react';
import Button from 'src/components/Button';
import Select from 'src/components/Inputs/Select';
import TextField from 'src/components/Inputs/TextField';
import TradeFormInputLabel from 'src/components/TradeForm/TradeFormInputLabel';
import MarketIcon from 'src/components/Utils/MarketIcon';
import useDriftActions from 'src/hooks/useDriftActions';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useDriftAccountsStore from 'src/stores/useDriftAccountsStore';
import UI_UTILS from 'src/utils/uiUtils';
import {
	PriceImpactInfo,
	getPriceImpactForTargetMarket,
} from '../../hooks/usePriceImpact';
import { TargettedPopupProps } from './TargettedPopup';
import useAccountSpotBalances from 'src/hooks/useAccountBankBalances';
import { AccountSpotBalance } from 'src/stores/useDriftAccountsStore';
import { LabelAndField } from '../TradeForm/TradeFormPieces';
import useMarkPrice from 'src/hooks/useMarkPrice';
import { useCallback } from 'react';
import { OrderedSpotMarkets } from 'src/environmentVariables/EnvironmentVariables';
import ValueDisplay from '../ValueDisplay';
import SkeletonValuePlaceholder from '../SkeletonValuePlaceholder/SkeletonValuePlaceholder';
import useAccountData from 'src/hooks/useAccountData';
import Text from 'src/components/Text/Text';
import InfoMessage from '../TradeForm/InfoMessage';
import { compareDriftProps } from 'src/utils/compareProps';
import Utility from '../Inputs/Utility';
import useMarketsInfoStore from 'src/stores/useMarketsInfoStore';
import useDebouncedValue from 'src/hooks/utils/useDebouncedValue';
import useMarketStateStore from '../../stores/useMarketStateStore';
import CloseBorrowSwapForm, {
	SWAP_FROM_MARKET_SELECTOR_ID,
	SWAP_TO_MARKET_SELECTOR_ID,
} from '../Swap/CloseBorrowSwapForm';
import CloseBorrowDepositForm from '../CloseBorrowDepositForm';
import InputAction from '../Inputs/InputAction';
import UnderlinedTabs from '../TabbedNavigation/UnderlinedTabs';
import useMemoizedOraclePrice from 'src/hooks/useMemoizedOraclePrice';
import useDriftClient from 'src/hooks/useDriftClient';
import useCurrentSettings from 'src/hooks/useCurrentSettings';
import PopoverWrapper from '../PopoverWrapper';
import { COLLATERAL_DROPDOWN_ID } from '../Inputs/CollateralInput';

interface CloseBorrowPopupProps {
	targetElementId: string;
	spotMarketIndex: number;
	onClose: () => void;
	align: TargettedPopupProps['side'];
	offset?: { x: number; y?: number };
}

interface BorrowCloseSizeSelectorProps {
	baseSizeBuyStringValue: string;
	baseSizeSellStringValue: string;
	assetToSellBaseSymbol: string;
	assetToBuyBaseSymbol: string;
	maxBaseSizeToBuy: string;
	onBaseSizeSellSideChange: (value: string) => void;
	onBaseSizeBuySideChange: (
		value: string,
		isMax?: boolean,
		round?: boolean
	) => void;
	buyingQuoteAsset: boolean;
}

interface WarningCheckProps {
	selectedOrderType: UIOrderType;
	baseAmountOfBuyBigNum: BigNum;
	baseAmountOfSellBigNum: BigNum;
	priceImpactInfo: PriceImpactInfo;
	buyingQuoteAsset: boolean;
	assetToSell: AccountSpotBalance;
	assetToBuy: AccountSpotBalance;
	priceBoxStringValue: string;
	minTradeSizeForMarket: number;
}

interface CloseBorrowWarningInfo {
	message?: string;
	severity: 'info' | 'warn' | 'error' | 'success';
	blocksSubmit: boolean;
}

const orderTypeSelectPortalId = 'closePositionOrderTypeSelect';
const marketSelectPortalId = 'closeBorrowMarketSelect';

export enum CloseBorrowOption {
	SpotTrade = 'SpotTrade',
	Swaps = 'Swaps',
	Deposit = 'Deposit',
}

export const CLOSE_BORROW_OPTIONS = [
	{
		label: 'Swaps',
		value: CloseBorrowOption.Swaps,
	},
	// temp disabling due to spot liquidity concerns
	// {
	// 	label: 'Spot Trade',
	// 	value: CloseBorrowOption.SpotTrade,
	// },
	{
		label: 'Deposit',
		value: CloseBorrowOption.Deposit,
	},
];

export const CLOSE_BORROW_FORM_NESTED_POPUP_IDS = [
	COLLATERAL_DROPDOWN_ID,
	SWAP_FROM_MARKET_SELECTOR_ID,
	SWAP_TO_MARKET_SELECTOR_ID,
];

const CloseBorrowPopup = React.forwardRef<
	HTMLDivElement,
	Pick<CloseBorrowPopupProps, 'spotMarketIndex' | 'onClose'> & {
		style?: CSSProperties;
	}
>((props, ref) => {
	const currentUserKey = useDriftAccountsStore((s) => s.currentUserKey);
	const spotBalances = useAccountSpotBalances(currentUserKey ?? '', null);
	const driftClient = useDriftClient();

	const [assetToBuy, setAssetToBuy] = useState<AccountSpotBalance>(undefined);
	const [closeBorrowOption, setCloseBorrowOption] = useState<string>(
		CloseBorrowOption.Swaps
	);

	const bufferedCloseBorrowAmount = UI_UTILS.getCloseBorrowAmountWithBuffer(
		driftClient,
		assetToBuy?.asset.marketIndex ?? 0,
		assetToBuy?.balance ?? BigNum.zero()
	).print();

	useEffect(() => {
		const foundAssetToBuy = spotBalances?.find(
			(bal) =>
				bal.asset.marketIndex === props.spotMarketIndex &&
				ENUM_UTILS.match(bal.balanceType, SpotBalanceType.BORROW)
		);
		setAssetToBuy(foundAssetToBuy);
	}, [spotBalances?.length]);

	const renderCloseBorrowOption = () => {
		switch (closeBorrowOption) {
			case CloseBorrowOption.SpotTrade:
				return <CloseBorrowSpotTrade {...props} />;
			case CloseBorrowOption.Swaps:
				return (
					<CloseBorrowSwapForm
						swapToSpotMarketIndex={props.spotMarketIndex}
						onClose={props.onClose}
					/>
				);
			case CloseBorrowOption.Deposit:
				return <CloseBorrowDepositForm {...props} />;
			default:
				return <CloseBorrowSpotTrade {...props} />;
		}
	};

	return (
		<PopoverWrapper
			className="min-w-[440px] flex flex-col p-4 w-90 space-y-3"
			ref={ref}
			style={props.style}
		>
			<div className="flex items-center justify-between">
				<Text.H4 className="text-text-default">Close Borrow</Text.H4>
				<div className="inline-flex items-center">
					<Text.H4 className="text-text-emphasis">
						-{bufferedCloseBorrowAmount}
					</Text.H4>
					<MarketIcon
						marketSymbol={COMMON_UI_UTILS.getBaseAssetSymbol(
							assetToBuy?.asset.symbol ?? ''
						)}
						className="w-5 h-5 mx-1"
					/>
					<Text.H3 className="text-text-emphasis">
						{assetToBuy?.asset.symbol ?? ''}
					</Text.H3>
				</div>
			</div>

			<UnderlinedTabs
				options={CLOSE_BORROW_OPTIONS}
				onChange={setCloseBorrowOption}
				currentSelection={closeBorrowOption}
			/>

			{renderCloseBorrowOption()}
		</PopoverWrapper>
	);
});

CloseBorrowPopup.displayName = 'CloseBorrowPopupV2';

/**
 * @param props
 * @returns
 */
export const CloseBorrowSpotTrade = memo(function CloseBorrowPopupInnerSelector(
	props: Omit<CloseBorrowPopupProps, 'targetElementId' | 'align'>
) {
	const [awaitingTx, setAwaitingTx] = useState(false);

	const currentUserKey = useDriftAccountsStore((s) => s.currentUserKey);

	const spotBalances = useAccountSpotBalances(currentUserKey ?? '', null);

	const marketInfoGetter = useMarketsInfoStore((s) => s.get);

	const currentAccount = useAccountData();

	const [currentSettings] = useCurrentSettings();

	const { slippageTolerance, allowInfSlippage } = useDriftStore(
		(s) => s.tradeForm
	);

	const actions = useDriftActions();

	const [priceBoxStringValue, setPriceBoxStringValue] = useState('0');
	const [maxBaseSizeToClose, setMaxBaseSizeToClose] = useState('0');
	const [maxLeverageFlag, setMaxLeverageFlag] = useState(true);

	const assetToBuy = useRef<AccountSpotBalance>(undefined);
	const assetToSell = useRef<AccountSpotBalance>(undefined);
	const assetToBuyUiMarket = useRef<UIMarket>(undefined);
	const assetToSellUiMarket = useRef<UIMarket>(undefined);

	const fetchedSellMarkPrice = useMarkPrice(assetToSellUiMarket.current);
	const fetchedBuyMarkPrice = useMarkPrice(assetToBuyUiMarket.current);

	const assetToSellMarkPrice = useRef(BigNum.zero(PRICE_PRECISION_EXP));
	const assetToBuyMarkPrice = useRef(BigNum.zero(PRICE_PRECISION_EXP));

	const baseAmountOfBuyBigNum = useRef(
		assetToBuy.current?.balance ?? undefined
	);
	const baseAmountOfSellBigNum = useRef(
		assetToSell.current?.balance ?? undefined
	);

	const [eligibleMarkets, setEligibleMarkets] = useState<SpotMarketConfig[]>(
		[]
	);
	const [selectedSpotMarketIndex, setSelectedSpotMarketIndex] = useState(1);

	const [baseAmountOfBuyStringValue, setBaseAmountOfBuyStringValue] = useState(
		baseAmountOfBuyBigNum.current?.print() ?? ''
	);
	const [baseAmountOfSellStringValue, setBaseAmountOfSellStringValue] =
		useState(baseAmountOfSellBigNum.current?.print() ?? '');

	const [selectedOrderType, setSelectedOrderType] = useState(
		UI_ORDER_TYPES.market.value
	);

	const [leverageBeforeTrade, setLeverageBeforeTrade] = useState(
		currentAccount?.marginInfo?.leverage ?? 0
	);

	const [leverageAfterTrade, setLeverageAfterTrade] = useState(0);

	const [warningInfo, setWarningInfo] =
		useState<CloseBorrowWarningInfo>(undefined);

	const buyingQuoteAsset = props.spotMarketIndex === 0;

	const getState = useDriftStore((s) => s.get);
	const getDlobState = useMarketStateStore((s) => s.get);

	const marketIndexToUse = buyingQuoteAsset
		? assetToSell.current?.asset?.marketIndex ?? 1
		: assetToBuy.current?.asset?.marketIndex ?? 0;

	const marketId = MarketId.createSpotMarket(marketIndexToUse);

	const oraclePrice = useMemoizedOraclePrice(marketId);

	const spotMarketAccount =
		marketInfoGetter().allMarketsInfo.spotLookup[
			buyingQuoteAsset
				? assetToSell.current?.asset.marketIndex
				: assetToBuy.current?.asset.marketIndex
		]?.account;

	const priceImpactInfo = useDebouncedValue(
		() =>
			getPriceImpactForTargetMarket(
				{
					marketId,
					side: buyingQuoteAsset ? 'sell' : 'buy',
					quoteSize: undefined,
					leadSide: 'base',
					baseSize: baseAmountOfBuyBigNum.current?.val.abs() ?? ZERO,
					uiOrderType: selectedOrderType,
					isSellPredictionMarket: false,
				},
				getState,
				getDlobState,
				oraclePrice
			),
		1000
	);

	const resetFields = () => {
		baseAmountOfBuyBigNum.current = BigNum.zero(
			assetToBuy.current?.asset?.precisionExp
		);
		baseAmountOfSellBigNum.current = BigNum.zero(
			assetToSell.current?.asset?.precisionExp
		);
		setBaseAmountOfBuyStringValue('');
		setBaseAmountOfSellStringValue('');
		setMaxLeverageFlag(false);
		setPriceBoxStringValue('');
	};

	const handleClose = () => {
		setAwaitingTx(true);

		props.onClose();

		let baseBigNumToUse = buyingQuoteAsset
			? baseAmountOfSellBigNum.current
			: baseAmountOfBuyBigNum.current;

		if (maxLeverageFlag) {
			const stepSize = BigNum.from(
				spotMarketAccount?.orderStepSize,
				baseBigNumToUse.precision
			);

			const mod = baseBigNumToUse.val.mod(stepSize.val);

			if (!mod.eq(ZERO)) {
				// if order base size not a multiple stepsize, add 1 stepsize to close out (positive dust instead of negative dust - best we can do for now)
				baseBigNumToUse = baseBigNumToUse.add(stepSize);
			}
		}

		actions.openSpotTradeFormOrder({
			side: buyingQuoteAsset ? 'sell' : 'buy',
			leadSide: 'base',
			baseSizeStringValue: baseBigNumToUse.print(),
			orderType: selectedOrderType,
			priceBoxStringValue: priceBoxStringValue,
			secondaryPriceBoxStringValue: '',
			reduceOnly: false,
			slippageTolerance,
			postOnly: false,
			targetMarketIndex: buyingQuoteAsset
				? assetToSell.current?.asset.marketIndex
				: assetToBuy.current?.asset.marketIndex,
			immediateOrCancel: false,
			currentPositionBaseSize: buyingQuoteAsset
				? assetToSell.current.balance.val.abs()
				: assetToBuy.current.balance.val.abs(),
			currentPositionDirection: buyingQuoteAsset ? 'short' : 'long',
			priceImpact: priceImpactInfo,
			maxLeverageSelected: false,
			oraclePrice: oraclePrice.val,
			currentSettings,
			spotMarketAccount: marketInfoGetter().getMarketInfoByIndexAndType(
				marketId.marketIndex,
				marketId.marketType
			).account as SpotMarketAccount,
		});
	};

	const updateBuySize = (value: string, isMax?: boolean, round?: boolean) => {
		value = UI_UTILS.disallowNegativeStringInput(value);

		if (!value) {
			resetFields();
			return;
		}

		// don't round if user input
		const buyStrValToUse = round
			? UI_UTILS.toFixedLocaleString(Number(value), 4)
			: value;
		setBaseAmountOfBuyStringValue(buyStrValToUse);

		const newBuyBigNum = BigNum.fromPrint(
			value,
			assetToBuy.current.asset.precisionExp
		);

		setMaxLeverageFlag(isMax);

		baseAmountOfBuyBigNum.current = newBuyBigNum;

		const { buyPrice, sellPrice } = getBuySellPrices();

		if (!sellPrice.eqZero() && !buyPrice.eqZero()) {
			const newSellNum =
				(newBuyBigNum.toNum() * buyPrice.toNum()) / sellPrice.toNum();

			const newSellStr = newSellNum.toString().includes('e')
				? '0'
				: newSellNum.toString();

			const newSellBigNum = BigNum.fromPrint(
				newSellStr,
				assetToSell.current.asset.precisionExp
			);

			const sellStrValToUse = round
				? UI_UTILS.toFixedLocaleString(newSellBigNum.toNum(), 4)
				: newSellBigNum.print();
			setBaseAmountOfSellStringValue(sellStrValToUse);
			baseAmountOfSellBigNum.current = newSellBigNum;
		}
	};

	const updateSellSize = (value: string) => {
		value = UI_UTILS.disallowNegativeStringInput(value);

		if (!value) {
			resetFields();
			return;
		}

		setMaxLeverageFlag(false);

		setBaseAmountOfSellStringValue(value);

		const newSellBigNum = BigNum.fromPrint(
			value,
			assetToSell.current.asset.precisionExp
		);
		baseAmountOfSellBigNum.current = newSellBigNum;

		const { buyPrice, sellPrice } = getBuySellPrices();

		if (!sellPrice.eqZero() && !buyPrice.eqZero()) {
			const newBuyNum =
				(newSellBigNum.toNum() * sellPrice.toNum()) / buyPrice.toNum();

			const newBuyStr = newBuyNum.toString().includes('e')
				? '0'
				: newBuyNum.toString();

			const newBuyBigNum = BigNum.fromPrint(
				newBuyStr,
				assetToBuy.current.asset.precisionExp
			);

			setBaseAmountOfBuyStringValue(newBuyBigNum.print());
			baseAmountOfBuyBigNum.current = newBuyBigNum;
		}
	};

	const updateLimitPrice = (value) => {
		value = UI_UTILS.disallowNegativeStringInput(value);

		if (!value) {
			resetFields();
			return;
		}

		setPriceBoxStringValue(value);
	};

	const getBuySellPrices = (): { buyPrice: BigNum; sellPrice: BigNum } => {
		let sellPriceToUse = assetToSellMarkPrice.current;
		let buyPriceToUse = assetToBuyMarkPrice.current;

		if (selectedOrderType === 'limit' && priceBoxStringValue) {
			if (buyingQuoteAsset) {
				sellPriceToUse = BigNum.fromPrint(
					priceBoxStringValue,
					PRICE_PRECISION_EXP
				);
			} else {
				buyPriceToUse = BigNum.fromPrint(
					priceBoxStringValue,
					PRICE_PRECISION_EXP
				);
			}
		}

		if (
			selectedOrderType === 'market' &&
			priceImpactInfo &&
			priceImpactInfo.priceImpactInputBaseSize?.gt(ZERO)
		) {
			if (buyingQuoteAsset) {
				sellPriceToUse = BigNum.from(
					priceImpactInfo.entryPrice,
					PRICE_PRECISION_EXP
				);
			} else {
				buyPriceToUse = BigNum.from(
					priceImpactInfo.entryPrice,
					PRICE_PRECISION_EXP
				);
			}
		}

		return { buyPrice: buyPriceToUse, sellPrice: sellPriceToUse };
	};

	const updateMarket = useCallback(
		(newSpotMarketIndex: string) => {
			assetToSell.current = spotBalances.find(
				(bal) =>
					ENUM_UTILS.match(bal.balanceType, SpotBalanceType.DEPOSIT) &&
					bal.asset.marketIndex === Number(newSpotMarketIndex)
			);
			assetToSellUiMarket.current = UIMarket.createPerpMarket(
				Number(newSpotMarketIndex)
			);

			setSelectedSpotMarketIndex(Number(newSpotMarketIndex));

			resetFields();
		},
		[spotBalances]
	);

	// initialize buy/sell markets once user spot balances load
	useEffect(() => {
		if (!spotBalances.length) return;

		if (!assetToBuy.current) {
			assetToBuy.current = spotBalances?.find(
				(bal) =>
					bal.asset.marketIndex === props.spotMarketIndex &&
					ENUM_UTILS.match(bal.balanceType, SpotBalanceType.BORROW)
			);
			assetToBuyUiMarket.current = UIMarket.createSpotMarket(
				assetToBuy.current.asset.marketIndex
			);
		}

		if (!assetToSell.current) {
			const marketsWithDeposits = spotBalances
				.filter((bal) =>
					ENUM_UTILS.match(bal.balanceType, SpotBalanceType.DEPOSIT)
				)
				.sort((a, b) => b.balance.abs().toNum() - a.balance.abs().toNum());

			// TODO - need to change this logic if we add spot markets settled in another stable besides usdc
			const eligibleMkts =
				props.spotMarketIndex === 0
					? marketsWithDeposits.map((mkt) => mkt.asset)
					: [OrderedSpotMarkets[props.spotMarketIndex]];

			if (
				eligibleMkts
					.map((mkt) => mkt.marketIndex)
					.includes(marketsWithDeposits[0].asset.marketIndex)
			) {
				assetToSell.current = marketsWithDeposits[0];
			} else {
				assetToSell.current = spotBalances.find(
					(bal) => bal.asset.marketIndex === 0
				);
			}

			assetToSellUiMarket.current = UIMarket.createSpotMarket(
				assetToSell.current?.asset?.marketIndex ??
					(props.spotMarketIndex === 0 ? 1 : 0)
			);

			setEligibleMarkets(eligibleMkts);
			setSelectedSpotMarketIndex(eligibleMkts[0]?.marketIndex ?? 1);
		}
	}, [spotBalances?.length]);

	// keep the max borrow amount the user can close updated + set that as the initial base size
	useEffect(() => {
		if (
			!assetToSell.current ||
			!assetToBuy.current ||
			!assetToBuyMarkPrice.current ||
			assetToBuyMarkPrice.current.eqZero()
		)
			return;

		let maxBaseToBuy;

		if (assetToBuy.current.asset.marketIndex !== 0) {
			maxBaseToBuy = BigNum.from(
				currentAccount.client
					.getMaxTradeSizeUSDCForSpot(
						assetToBuy.current.asset.marketIndex,
						PositionDirection.LONG
					)
					.mul(assetToBuy.current.asset.precision)
					.div(assetToBuyMarkPrice.current.val),
				assetToBuy.current.asset.precisionExp
			);

			maxBaseToBuy = BigNum.min(maxBaseToBuy, assetToBuy.current.balance);
		} else {
			maxBaseToBuy = assetToBuy.current.balance;
		}

		const maxToBuyStr = maxBaseToBuy.print();
		setMaxBaseSizeToClose(maxToBuyStr);

		// init buy size to max borrow close if not changed yet
		if (
			!baseAmountOfBuyBigNum.current &&
			!assetToSellMarkPrice.current.eqZero() &&
			!assetToBuyMarkPrice.current.eqZero()
		) {
			updateBuySize(maxToBuyStr, true, false);
		}
	}, [
		assetToSell.current?.quoteValue?.toString(),
		assetToBuy.current?.quoteValue?.toString(),
		assetToSellMarkPrice.current?.toString(),
		assetToBuyMarkPrice.current?.toString(),
	]);

	// keep the mark price of each asset updated
	useEffect(() => {
		// TODO out of scope for this PR but need to use usdc oracle
		if (
			assetToBuyUiMarket.current &&
			assetToBuyUiMarket.current.isStableCoinMarket
		) {
			if (assetToBuyMarkPrice.current.eqZero()) {
				assetToBuyMarkPrice.current = BigNum.fromPrint(
					'1',
					PRICE_PRECISION_EXP
				);
			}

			// default the limit price to first mark price we fetch
			if (
				assetToSellMarkPrice.current.eqZero() &&
				(!priceBoxStringValue || priceBoxStringValue === '0')
			) {
				setPriceBoxStringValue(fetchedSellMarkPrice.print());
			}

			assetToSellMarkPrice.current = fetchedSellMarkPrice;
		} else if (
			assetToSellUiMarket.current &&
			assetToSellUiMarket.current.isStableCoinMarket
		) {
			if (assetToSellMarkPrice.current.eqZero()) {
				assetToSellMarkPrice.current = BigNum.fromPrint(
					'1',
					PRICE_PRECISION_EXP
				);
			}

			// default the limit price to first mark price we fetch
			if (
				assetToBuyMarkPrice.current.eqZero() &&
				(!priceBoxStringValue || priceBoxStringValue === '0')
			) {
				setPriceBoxStringValue(fetchedBuyMarkPrice.print());
			}

			assetToBuyMarkPrice.current = fetchedBuyMarkPrice;
		}
	}, [
		assetToBuyUiMarket.current,
		assetToSellUiMarket.current,
		fetchedSellMarkPrice?.toString(),
		fetchedBuyMarkPrice?.toString(),
	]);

	// keep leverage change up to date + make sure input is valid when it changes
	useEffect(() => {
		if (
			!currentAccount ||
			!currentAccount.client ||
			!baseAmountOfBuyBigNum.current
		) {
			return;
		}

		setLeverageBeforeTrade(currentAccount?.marginInfo?.leverage);

		const { buyPrice } = getBuySellPrices();

		const tradeQuoteAmount = buyPrice
			.mul(baseAmountOfBuyBigNum.current)
			.shift(baseAmountOfBuyBigNum.current.precision.neg());

		const leverageAfterTrade = convertToNumber(
			currentAccount.client.accountLeverageRatioAfterTrade(
				selectedSpotMarketIndex,
				MarketType.SPOT,
				tradeQuoteAmount.val,
				buyingQuoteAsset ? PositionDirection.SHORT : PositionDirection.LONG
			) ?? ZERO,
			TEN_THOUSAND
		);

		setLeverageAfterTrade(leverageAfterTrade);

		const warning = checkForWarnings({
			selectedOrderType,
			baseAmountOfBuyBigNum: baseAmountOfBuyBigNum.current,
			baseAmountOfSellBigNum: baseAmountOfSellBigNum.current,
			priceImpactInfo,
			buyingQuoteAsset,
			assetToSell: assetToSell.current,
			assetToBuy: assetToBuy.current,
			priceBoxStringValue,
			minTradeSizeForMarket:
				BigNum.from(
					spotMarketAccount?.minOrderSize,
					spotMarketAccount?.decimals
				)?.toNum() ?? 0,
		});

		setWarningInfo(warning);
	}, [
		currentAccount?.marginInfo?.leverage,
		baseAmountOfBuyBigNum.current?.toString(),
		selectedSpotMarketIndex,
		selectedOrderType,
		priceBoxStringValue,
		buyingQuoteAsset,
		assetToSellMarkPrice.current?.toString(),
		assetToBuyMarkPrice.current?.toString(),
		priceImpactInfo,
	]);

	useEffect(() => {
		if (baseAmountOfBuyStringValue) {
			updateBuySize(baseAmountOfBuyStringValue);
		}
	}, [priceBoxStringValue, selectedOrderType]);

	return (
		<div className="flex flex-col space-y-3">
			<div className="w-full">
				<TradeFormInputLabel className="mt-0">Spot Pair</TradeFormInputLabel>
				<Select.MarketSelector
					id={marketSelectPortalId}
					selection={selectedSpotMarketIndex.toString()}
					options={eligibleMarkets.map((mkt) => {
						return {
							label: UIMarket.createSpotMarket(mkt.marketIndex).marketName,
							value: mkt.marketIndex.toString(),
						};
					})}
					onChange={updateMarket}
					useFullWidth
					disabled={eligibleMarkets.length < 2}
					marketType={MarketType.SPOT}
				/>
			</div>

			<div className="flex items-end justify-between w-full space-x-2">
				<LabelAndField>
					<TradeFormInputLabel className="mt-0">Order Type</TradeFormInputLabel>
					<Select.Form
						id={orderTypeSelectPortalId}
						selection={selectedOrderType}
						options={UI_ORDER_TYPES_LIST.filter(
							(val) => val.value == 'market' || val.value == 'limit'
						)}
						onChange={setSelectedOrderType as (value: string) => void}
						useFullWidth
					/>
				</LabelAndField>
				{selectedOrderType == 'limit' && (
					<LabelAndField>
						<TradeFormInputLabel className="mt-0">
							Limit Price
						</TradeFormInputLabel>
						<TextField.Default
							value={priceBoxStringValue}
							disabled={false}
							type="number"
							onChange={updateLimitPrice}
							showIconForMarketSymbol={'USDC'}
							stepAmount={0.01}
						/>
					</LabelAndField>
				)}
				{selectedOrderType == 'market' && (
					<LabelAndField>
						<TradeFormInputLabel className="h-[14px] mt-0">
							{' '}
						</TradeFormInputLabel>
						<div className="whitespace-nowrap pointer-events-none bg-input-bg rounded-sm w-full px-2 flex items-center text-text-default h-[32px] select-none">
							Market Price
						</div>
					</LabelAndField>
				)}
			</div>

			<div className="flex space-x-2">
				{assetToBuyUiMarket.current && assetToSellUiMarket.current && (
					<BorrowCloseSizeSelectorMemo
						baseSizeBuyStringValue={baseAmountOfBuyStringValue}
						baseSizeSellStringValue={baseAmountOfSellStringValue}
						assetToSellBaseSymbol={COMMON_UI_UTILS.getBaseAssetSymbol(
							assetToSell.current?.asset.symbol ?? ''
						)}
						assetToBuyBaseSymbol={COMMON_UI_UTILS.getBaseAssetSymbol(
							assetToBuy.current?.asset.symbol ?? ''
						)}
						maxBaseSizeToBuy={maxBaseSizeToClose}
						onBaseSizeSellSideChange={updateSellSize}
						onBaseSizeBuySideChange={updateBuySize}
						buyingQuoteAsset={buyingQuoteAsset}
					/>
				)}
			</div>
			<Utility.VERTDIVIDER />
			<div className="flex flex-col">
				{assetToBuy.current &&
					assetToSell.current &&
					baseAmountOfBuyBigNum.current && (
						<div className="mb-2 space-y-1">
							<Text.BODY3 className="text-text-label">After close</Text.BODY3>
							<ValueDisplay.ValueChange
								label="Borrow Size"
								previousValue={assetToBuy.current.balance.neg()}
								afterValue={assetToBuy.current.balance
									.neg()
									.add(baseAmountOfBuyBigNum.current)}
								previousValuePrint={assetToBuy.current.balance
									.neg()
									.prettyPrint(true)}
								afterValuePrint={`${assetToBuy.current.balance
									.neg()
									.add(baseAmountOfBuyBigNum.current)
									.prettyPrint(true)} ${COMMON_UI_UTILS.getBaseAssetSymbol(
									assetToBuy.current.asset.symbol
								)}`}
								forceWhite
								nullOverride={!assetToBuy.current || !assetToSell.current}
								customOverride={
									<SkeletonValuePlaceholder
										className="h-[14px] w-[50px]"
										loading={false}
									/>
								}
								overrideNoValueChange={baseAmountOfBuyBigNum.current.eqZero()}
							/>
							{selectedOrderType === 'market' && (
								<ValueDisplay.Default
									label="Max Slippage"
									value={
										<>
											{allowInfSlippage ? 'Infinite' : `${slippageTolerance}%`}
										</>
									}
								/>
							)}
							<ValueDisplay.ValueChange
								label="Est. Liq. Price"
								previousValue={UI_UTILS.getSpotLiqPriceNum(
									currentAccount?.client,
									assetToBuy.current.asset.marketIndex
								)}
								afterValue={UI_UTILS.getSpotLiqPriceNum(
									currentAccount?.client,
									assetToBuy.current.asset.marketIndex,
									baseAmountOfBuyBigNum.current,
									true
								)}
								previousValuePrint={UI_UTILS.getSpotLiqPriceStr(
									currentAccount?.client,
									assetToBuy.current.asset.marketIndex
								)}
								afterValuePrint={UI_UTILS.getSpotLiqPriceStr(
									currentAccount?.client,
									assetToBuy.current.asset.marketIndex,
									baseAmountOfBuyBigNum.current,
									true
								)}
								forceWhite
								nullOverride={!assetToBuy.current || !assetToSell.current}
								customOverride={
									<SkeletonValuePlaceholder
										className="h-[14px] w-[50px]"
										loading={false}
									/>
								}
								overrideNoValueChange={baseAmountOfBuyBigNum.current.eqZero()}
							/>
							<ValueDisplay.ValueChange
								label="Acct. Leverage"
								rightSymbol="x"
								previousValue={leverageBeforeTrade}
								afterValue={leverageAfterTrade}
								previousValuePrint={leverageBeforeTrade.toLocaleString(
									'en-US',
									{
										maximumSignificantDigits: 3,
									}
								)}
								afterValuePrint={leverageAfterTrade.toLocaleString('en-US', {
									maximumSignificantDigits: 3,
								})}
								forceWhite
								nullOverride={!currentAccount || !currentAccount.client}
								customOverride={
									<SkeletonValuePlaceholder
										className="h-[14px] w-[50px]"
										loading={false}
									/>
								}
								overrideNoValueChange={baseAmountOfBuyBigNum.current.eqZero()}
							/>
						</div>
					)}

				{warningInfo && (
					<CloseBorrowWarnings
						message={warningInfo.message}
						severity={warningInfo.severity}
					/>
				)}

				<div>
					<Button.BigSemantic
						positive={!buyingQuoteAsset}
						disabled={
							awaitingTx ||
							!assetToBuy.current ||
							!assetToSell.current ||
							!baseAmountOfBuyBigNum.current ||
							baseAmountOfBuyBigNum.current?.eqZero() ||
							warningInfo?.blocksSubmit ||
							!priceImpactInfo
						}
						onClick={handleClose}
					>
						{`${buyingQuoteAsset ? 'Sell' : 'Buy'} ${
							buyingQuoteAsset
								? baseAmountOfSellStringValue
								: baseAmountOfBuyStringValue
						} ${COMMON_UI_UTILS.getBaseAssetSymbol(
							(buyingQuoteAsset ? assetToSell : assetToBuy).current?.asset
								?.symbol ?? ''
						)}`}
					</Button.BigSemantic>
				</div>
			</div>
		</div>
	);
});

const checkForWarnings = ({
	selectedOrderType,
	baseAmountOfBuyBigNum,
	baseAmountOfSellBigNum,
	priceImpactInfo,
	buyingQuoteAsset,
	assetToSell,
	assetToBuy,
	priceBoxStringValue,
	minTradeSizeForMarket,
}: WarningCheckProps): CloseBorrowWarningInfo => {
	let sizeTooSmall;
	let limitPriceCrossing;
	let baseAssetSymbol;

	try {
		baseAssetSymbol = COMMON_UI_UTILS.getBaseAssetSymbol(
			(buyingQuoteAsset ? assetToSell : assetToBuy).asset.symbol
		);

		sizeTooSmall =
			(buyingQuoteAsset
				? baseAmountOfSellBigNum.toNum()
				: baseAmountOfBuyBigNum.toNum()) < minTradeSizeForMarket;

		if (selectedOrderType === 'limit' && priceImpactInfo) {
			const limitPrice = BigNum.fromPrint(
				priceBoxStringValue,
				PRICE_PRECISION_EXP
			);
			const entryPrice = BigNum.from(
				priceImpactInfo.entryPrice,
				PRICE_PRECISION_EXP
			);

			limitPriceCrossing = buyingQuoteAsset
				? limitPrice.lt(entryPrice)
				: limitPrice.gt(entryPrice);
		} else {
			limitPriceCrossing = false;
		}
	} catch (_e) {
		//swallow error, likely just means values arent loaded yet
		return { severity: 'error', blocksSubmit: true };
	}

	if (sizeTooSmall) {
		return {
			message: `Order size must be greater than ${minTradeSizeForMarket} ${baseAssetSymbol}`,
			severity: 'error',
			blocksSubmit: true,
		};
	}

	if (limitPriceCrossing) {
		return {
			message: 'This order will start to execute immediately',
			severity: 'warn',
			blocksSubmit: false,
		};
	}

	return undefined;
};

const CloseBorrowWarnings = memo(function WarningSelector({
	message,
	severity,
}: Omit<CloseBorrowWarningInfo, 'blocksSubmit'>) {
	if (!message || !severity) return;
	return <InfoMessage className="mb-1" type={severity} message={message} />;
},
compareDriftProps);

const BorrowCloseSizeSelectorMemo = memo(function SizeSelector({
	baseSizeBuyStringValue,
	baseSizeSellStringValue,
	assetToSellBaseSymbol,
	assetToBuyBaseSymbol,
	maxBaseSizeToBuy,
	onBaseSizeSellSideChange,
	onBaseSizeBuySideChange,
	buyingQuoteAsset,
}: BorrowCloseSizeSelectorProps) {
	return (
		<>
			<div className="flex items-end justify-between w-full space-x-2">
				<LabelAndField className="justify-start">
					<TradeFormInputLabel>Size</TradeFormInputLabel>
					<TextField.Default
						type="number"
						onChange={
							buyingQuoteAsset
								? onBaseSizeSellSideChange
								: onBaseSizeBuySideChange
						}
						value={
							buyingQuoteAsset
								? baseSizeSellStringValue
								: baseSizeBuyStringValue
						}
						showIconForMarketSymbol={
							buyingQuoteAsset ? assetToSellBaseSymbol : assetToBuyBaseSymbol
						}
					/>
				</LabelAndField>

				<LabelAndField className="justify-end">
					<span className="flex justify-end">
						<InputAction
							onClick={() => {
								onBaseSizeBuySideChange(maxBaseSizeToBuy, true);
							}}
							className="mb-1"
						>
							<span className="mr-2">To Close:</span>
							<span className="mr-1">{maxBaseSizeToBuy}</span>
							<span>{assetToBuyBaseSymbol}</span>
						</InputAction>
					</span>

					<TextField.Default
						type="number"
						onChange={
							buyingQuoteAsset
								? onBaseSizeBuySideChange
								: onBaseSizeSellSideChange
						}
						value={
							buyingQuoteAsset
								? baseSizeBuyStringValue
								: baseSizeSellStringValue
						}
						showIconForMarketSymbol={
							buyingQuoteAsset ? assetToBuyBaseSymbol : assetToSellBaseSymbol
						}
					/>
				</LabelAndField>
			</div>
		</>
	);
});

export default React.memo(CloseBorrowPopup);
