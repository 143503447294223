'use client';

import {
	BigNum,
	BN,
	getTokenAmount,
	QUOTE_PRECISION_EXP,
	SpotBalanceType,
	SpotMarketConfig,
	SPOT_MARKET_BALANCE_PRECISION_EXP,
} from '@drift-labs/sdk';
import { matchEnum } from '@drift/common';
import { OrderedSpotMarkets } from 'src/environmentVariables/EnvironmentVariables';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import useDriftClientIsReady from './useDriftClientIsReady';
import useUserAccountIsReady from './useUserAccountIsReady';
import useWithdrawableBankBalance from './useWithdrawableBankBalance';

const getZeroBalance = (bank: SpotMarketConfig) => {
	return {
		asset: bank,
		assetBankBalance: BigNum.zero(SPOT_MARKET_BALANCE_PRECISION_EXP),
		assetBaseBalance: BigNum.zero(bank.precisionExp),
		assetQuoteValue: BigNum.zero(QUOTE_PRECISION_EXP),
		liabilityBankBalance: BigNum.zero(SPOT_MARKET_BALANCE_PRECISION_EXP),
		liabilityBaseBalance: BigNum.zero(bank.precisionExp),
		liabilityQuoteValue: BigNum.zero(QUOTE_PRECISION_EXP),
		netBaseBalance: BigNum.zero(bank.precisionExp),
		netQuoteValue: BigNum.zero(QUOTE_PRECISION_EXP),
		withdrawLimitBase: BigNum.zero(bank.precisionExp),
		spotAssetDecimals: 6,
	};
};

const useAccountTargetSpotBalance = (
	bank: SpotMarketConfig,
	userKey?: string,
	reduceOnly = true
): {
	asset: SpotMarketConfig;
	assetBankBalance: BigNum;
	assetBaseBalance: BigNum;
	assetQuoteValue: BigNum;
	liabilityBankBalance: BigNum;
	liabilityBaseBalance: BigNum;
	liabilityQuoteValue: BigNum;
	netBaseBalance: BigNum;
	netQuoteValue: BigNum;
	withdrawLimitBase: BigNum;
	spotAssetDecimals: number;
} => {
	const userAccountIsReady = useUserAccountIsReady(userKey);
	const userAccountClient = useDriftAccountStore(
		(s) => s.accounts[userKey ?? s.currentUserKey]?.client
	);
	const driftClientIsReady = useDriftClientIsReady();
	const driftClient = useDriftStore((s) => s.driftClient.client);

	const userMaxWithdrawBaseBalance = useWithdrawableBankBalance(
		bank,
		userKey,
		reduceOnly
	);

	if (!userAccountIsReady) return getZeroBalance(bank);
	if (!driftClientIsReady) return getZeroBalance(bank);
	if (!userAccountClient) return getZeroBalance(bank);

	const userBankBalances = userAccountClient.getUserAccount().spotPositions;

	const bankAccount = driftClient.getSpotMarketAccount(bank.marketIndex);

	const assetBankBalance = BigNum.from(
		userBankBalances.find(
			(bal) =>
				bal.marketIndex === bank.marketIndex &&
				matchEnum(bal.balanceType, SpotBalanceType.DEPOSIT)
		)?.scaledBalance ?? new BN(0),
		SPOT_MARKET_BALANCE_PRECISION_EXP
	);

	const assetTokenBalance = BigNum.from(
		getTokenAmount(assetBankBalance.val, bankAccount, SpotBalanceType.DEPOSIT),
		bankAccount.decimals
	).shiftTo(bank.precisionExp);

	const liabilityBankBalance = BigNum.from(
		userBankBalances.find(
			(bal) =>
				bal.marketIndex === bank.marketIndex &&
				matchEnum(bal.balanceType, SpotBalanceType.BORROW)
		)?.scaledBalance ?? new BN(0),
		SPOT_MARKET_BALANCE_PRECISION_EXP
	);

	const liabilityTokenBalance = BigNum.from(
		getTokenAmount(
			liabilityBankBalance.val,
			bankAccount,
			SpotBalanceType.BORROW
		),
		bankAccount.decimals
	).shiftTo(bank.precisionExp);

	const assetQuoteValue = BigNum.from(
		userAccountClient.getSpotMarketAssetValue(bank.marketIndex),
		QUOTE_PRECISION_EXP
	);

	const liabilityQuoteValue = BigNum.from(
		userAccountClient.getSpotMarketLiabilityValue(bank.marketIndex),
		QUOTE_PRECISION_EXP
	);

	const userSpotBalance = {
		asset: OrderedSpotMarkets[bank.marketIndex],
		assetBankBalance,
		assetBaseBalance: assetTokenBalance,
		assetQuoteValue,
		liabilityBankBalance,
		liabilityBaseBalance: liabilityTokenBalance,
		liabilityQuoteValue,
		netBaseBalance: assetTokenBalance.sub(liabilityTokenBalance),
		netQuoteValue: assetQuoteValue.sub(liabilityQuoteValue),
		withdrawLimitBase: userMaxWithdrawBaseBalance,
		spotAssetDecimals: bankAccount.decimals,
	};

	return userSpotBalance;
};

export default useAccountTargetSpotBalance;
