'use client';

import { OrderedSpotMarkets } from 'src/environmentVariables/EnvironmentVariables';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';

const useSwapMarkets = () => {
	const { fromMarketIndex, toMarketIndex } = useDriftStore((s) => s.swap);
	const swapFromMarket = OrderedSpotMarkets[fromMarketIndex];
	const swapToMarket = OrderedSpotMarkets[toMarketIndex];

	return { swapFromMarket, swapToMarket };
};

export default useSwapMarkets;
