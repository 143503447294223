import React from 'react';
import Tooltip from './Tooltip';
import Text from '../Text/Text';
import { twMerge } from 'tailwind-merge';
import { AprDetails } from 'src/hooks/useAprBreakdowns';

export type AprBreakdownTooltipProps = {
	symbol: string;
	depositAprPercent: number;
	marketAprBreakdowns: Array<Omit<AprDetails, 'icon'>>;
	children: React.ReactNode;
};

export const AprBreakdownRow = (props: {
	source: string;
	apr: number;
	hideApr?: boolean;
	helperText?: string | React.ReactNode;
}) => {
	return (
		<>
			<div
				className={twMerge(
					'flex items-center justify-between w-full',
					props.helperText && 'border-t border-text-secondary pt-2 mt-2'
				)}
			>
				<span>{props.source}</span>
				{!props.hideApr && (
					<span className="text-positive-green">{props.apr}%</span>
				)}
			</div>
			{props.helperText && (
				<div className="leading-4 opacity-80">
					<Text.MICRO1>{props.helperText}</Text.MICRO1>
				</div>
			)}
		</>
	);
};

const AprBreakdownTooltip = ({
	depositAprPercent,
	marketAprBreakdowns,
	children,
}: AprBreakdownTooltipProps) => {
	return (
		<Tooltip
			allowHover
			content={
				<div className={`flex flex-col w-[220px]`}>
					<AprBreakdownRow
						source="Lending"
						apr={+depositAprPercent.toFixed(2)}
					/>
					{marketAprBreakdowns.map((breakdown) => (
						<AprBreakdownRow
							key={breakdown.source}
							source={breakdown.source}
							apr={+breakdown.apr.toFixed(2)}
							hideApr={breakdown.hideApr}
							helperText={breakdown.helperText}
						/>
					))}
				</div>
			}
		>
			{children}
		</Tooltip>
	);
};

export default AprBreakdownTooltip;
