import Text from 'src/components/Text/Text';
import Switch from 'src/components/Switch';
import Tooltip from '../Tooltip/Tooltip';
import { Info } from '@drift-labs/icons';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { AdvancedChip } from '../Chips/AdvancedFeatureChip';

interface SettingsSwitchProps {
	label: string;
	checked: boolean;
	onChange: () => void;
	disabled?: boolean;
	spacedOut?: boolean;
	includeLineSpace?: boolean;
	textClass?: string;
	tooltipContent?: string | ReactNode;
	className?: string;
	betaFeature?: boolean;
}

const SettingsSwitch = ({
	label,
	checked,
	onChange,
	disabled,
	spacedOut = true,
	includeLineSpace = true,
	textClass,
	tooltipContent: tooltipText,
	className,
	betaFeature,
}: SettingsSwitchProps) => {
	return (
		<div
			key={label}
			className={twMerge(
				`flex items-center`,
				spacedOut ? 'justify-between' : 'gap-2',
				includeLineSpace ? 'mb-6' : '',
				className
			)}
		>
			{tooltipText ? (
				<Tooltip
					className={`inline-flex items-center ${
						textClass ? textClass : 'text-text-label'
					}`}
					content={tooltipText}
					allowHover
				>
					<Text.BODY2>{label}</Text.BODY2>
					<Info className={`ml-1`} size={12} />
					{betaFeature && <AdvancedChip customLabel="BETA" />}
				</Tooltip>
			) : (
				<Text.BODY2 className={`${textClass ? textClass : 'text-text-label'}`}>
					{label}
				</Text.BODY2>
			)}
			<Switch checked={checked} disabled={disabled} onChange={onChange} />
		</div>
	);
};

export default SettingsSwitch;
