import React, { PropsWithChildren, ReactNode } from 'react';
import Text from './Text/Text';
import ValueChangeDisplay from './ValueChangeDisplay';
import { twMerge } from 'tailwind-merge';

const LabelAndValue = (
	props: PropsWithChildren<{
		label: string | ReactNode;
		warn?: boolean;
		rightPadding?: string;
		className?: string;
	}>
) => {
	return (
		<Text.BODY3
			className={twMerge(
				`flex items-center justify-between w-full`,
				props.className
			)}
		>
			<div
				className={`${
					props.warn ? 'text-warn-yellow' : 'text-text-secondary'
				} shrink-0`}
			>
				{props.label}
			</div>

			<div className={`text-text-default ${props.rightPadding ?? ''}`}>
				{props.children}
			</div>
		</Text.BODY3>
	);
};

const ValueChange = ({
	label,
	nullOverride,
	customOverride,
	...props
}: {
	label: string | ReactNode;
	nullOverride?: boolean;
	customOverride?: ReactNode;
} & Parameters<typeof ValueChangeDisplay>[0]) => {
	return (
		<LabelAndValue label={label}>
			{nullOverride ? (
				customOverride ? (
					customOverride
				) : (
					'-'
				)
			) : (
				<ValueChangeDisplay {...props} />
			)}
		</LabelAndValue>
	);
};

const Default = ({
	label,
	value,
	warn,
	rightPadding,
	className,
}: {
	label: string | ReactNode;
	value: string | ReactNode;
	warn?: boolean;
	rightPadding?: string;
	className?: string;
}) => {
	return (
		<LabelAndValue
			label={label}
			warn={warn}
			rightPadding={rightPadding}
			className={className}
		>
			<div className={warn ? 'text-warn-yellow' : 'text-text-default'}>
				{value ?? '-'}
			</div>
		</LabelAndValue>
	);
};

const ValueDisplay = {
	ValueChange: React.memo(ValueChange),
	Default,
};

export default ValueDisplay;
