import { twMerge } from 'tailwind-merge';
import Text from '../Text/Text';

export default function InputAction({
	onClick,
	children,
	className,
}: {
	onClick: () => void;
	children: React.ReactNode;
	className?: string;
}) {
	return (
		<Text.BODY3
			className={twMerge(
				'flex px-1 rounded-sm bg-button-secondary-bg item-center text-text-secondary-button py-[2px] cursor-pointer whitespace-nowrap',
				className
			)}
			onClick={onClick}
		>
			{children}
		</Text.BODY3>
	);
}
