'use client';

import { useCallback } from 'react';
import useSafePush from '../useSafePush';

/**
 * The swap page's params are derived from the URL, hence to update the swap params is to update the URL.
 */
const useUpdateSwapParams = () => {
	const safePush = useSafePush();

	const updateSwapParams = useCallback(
		(fromTokenSymbol: string, toTokenSymbol: string) => {
			safePush(`/swap/${fromTokenSymbol}-${toTokenSymbol}`);
		},
		[]
	);

	return updateSwapParams;
};

export default useUpdateSwapParams;
