import Text from '../Text/Text';

const PoweredByJupiter = () => {
	return (
		<div className="flex items-center justify-center w-full gap-1">
			<Text.BODY3 className="text-center text-text-secondary">
				Powered by Jupiter
			</Text.BODY3>
			<img src={'/assets/icons/jupiter-logo.svg'} className="w-4 h-4" />
		</div>
	);
};

export default PoweredByJupiter;
