'use client';

import {
	BN,
	BigNum,
	FOUR,
	JupiterClient,
	QuoteResponse,
	SpotBalanceType,
	SpotMarketConfig,
	SwapMode,
	convertToNumber,
} from '@drift-labs/sdk';
import { matchEnum } from '@drift/common';
import { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'react-use';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { notify } from 'src/utils/notifications';
import useAccountSpotBalances from '../useAccountBankBalances';
import useAccountData from '../useAccountData';
import useCurrentSettings from '../useCurrentSettings';
import useDriftActions from '../useDriftActions';
import useDriftClientIsReady from '../useDriftClientIsReady';
import useSwapMarginConfig from './useSwapMarginConfig';
import useAppEventEmitter from '../useAppEventEmitter';
import UI_UTILS from 'src/utils/uiUtils';
import Env from 'src/environmentVariables/EnvironmentVariables';
import { useExceedsMaxSpotBalance } from '../useMaxSpotBalance';

const DEFAULT_MAX_ACCOUNTS = 52;
const DEFAULT_MAX_ACCOUNTS_BIG = 60;
const MAX_ACCOUNTS_SWAP_BUFFER = Env.maxAccountsSwapBuffer;

const biggerMaxAccountsMarketIndexes = [
	17, // dSOL
	22, // PYUSD
];

export default function useSwapFormEngine(
	swapFromMarket: SpotMarketConfig,
	swapToMarket: SpotMarketConfig,
	defaultSwapMode: SwapMode = 'ExactIn'
) {
	const actions = useDriftActions();
	const [slippage, connection] = useDriftStore((s) => [
		s.tradeForm.slippageTolerance,
		s.connection.current,
	]);
	const [savedSettings, setSavedSettings] = useCurrentSettings();
	const currentAccount = useAccountData();
	const currentUserKey = currentAccount?.userKey;
	const { allowMargin } = useSwapMarginConfig();
	const accountBalances = useAccountSpotBalances(currentUserKey, null);
	const driftClientIsReady = useDriftClientIsReady();
	const isGeoblocked = useDriftStore((s) => s.isGeoblocked);
	const setStore = useDriftStore((s) => s.set);
	const appEventEmitter = useAppEventEmitter();
	const willExceedMaxSpotBalance = useExceedsMaxSpotBalance(
		swapToMarket.marketIndex
	);

	const [swapFromAmount, setSwapFromAmount] = useState<string>('');
	const [swapToAmount, setSwapToAmount] = useState<string>('');
	const [quoteDetails, setQuoteDetails] = useState<QuoteResponse | null>(null);
	const [swapPrice, setSwapPrice] = useState(0);
	const [swapMode, setSwapMode] = useState<SwapMode>(defaultSwapMode);
	const [isLoading, setIsLoading] = useState(false);
	const [isApprovingTxn, setIsApprovingTxn] = useState(false); // this is needed because it takes a couple of seconds for the wallet approval to show up, and we don't want the user to spam the swap button
	const [maxSwapAmountDetails, setMaxSwapAmountDetails] = useState<{
		inAmount: BN;
		outAmount: BN;
		leverage: BN;
	}>(undefined);
	const [additionalAccountsBuffer, setAdditionalAccountsBuffer] = useState(
		MAX_ACCOUNTS_SWAP_BUFFER
	);

	const swapFromMarketIndexRef = useRef(swapFromMarket.marketIndex);
	const swapToMarketIndexRef = useRef(swapToMarket.marketIndex);
	const isApprovingTxnRef = useRef(isApprovingTxn);
	const getRouteRef = useRef(getRoute);

	const isExactIn = swapMode === 'ExactIn';
	const slippageBps = slippage * 100;
	const enableVersionedTx = savedSettings.enableVersionedTx;
	const directRouteSwaps = savedSettings.directRouteSwaps;

	// input variables
	const fromTokenBalanceAccount = accountBalances.find(
		(acc) => acc.asset.marketIndex === swapFromMarket.marketIndex
	);
	let fromTokenBalance =
		fromTokenBalanceAccount?.balance ??
		BigNum.zero(swapFromMarket.precisionExp);
	fromTokenBalance = matchEnum(
		SpotBalanceType.BORROW,
		fromTokenBalanceAccount?.balanceType
	)
		? fromTokenBalance.neg()
		: fromTokenBalance;

	const swapFromAmountBigNum = BigNum.fromPrint(
		swapFromAmount,
		swapFromMarket.precisionExp
	);

	const swapToAmountBigNum = BigNum.fromPrint(
		swapToAmount,
		swapToMarket.precisionExp
	);

	const desiredSwapAmount = isExactIn
		? swapFromAmountBigNum
		: swapToAmountBigNum;

	const desiredSwapAmountNum = desiredSwapAmount.toNum();

	const inputNumericAmount = isExactIn
		? swapFromAmountBigNum.toNum()
		: swapToAmountBigNum.toNum();

	// margin variables
	const maxLeverageAllowed = BigNum.from(
		maxSwapAmountDetails?.leverage.toString(),
		FOUR
	).toNum();
	const maxSwapSize = calcMaxSwapSize();
	const currentLeverage = currentAccount?.marginInfo?.leverage;
	const isInsufficientBalance = inputNumericAmount > +maxSwapSize.toString();
	const borrowAmount = BigNum.from(
		inputNumericAmount - +fromTokenBalance.toString(),
		swapFromMarket.precisionExp
	);

	const toTokenBalanceAccount = accountBalances.find(
		(acc) => acc.asset.marketIndex === swapToMarket.marketIndex
	);
	const willReduceBorrow = matchEnum(
		toTokenBalanceAccount?.balanceType,
		SpotBalanceType.BORROW
	);
	const borrowToBeRepaid = willReduceBorrow
		? BigNum.min(toTokenBalanceAccount.balance, swapToAmountBigNum)
		: BigNum.zero();

	// Logic to make sure user input choice matches quote
	// This should prevent users from accidentally swapping with a stale quote that doesn't match the current input
	let marketsMatchQuote = false;
	if (
		quoteDetails &&
		!quoteDetails.error &&
		swapFromMarket &&
		swapToMarket &&
		swapFromAmount &&
		swapToAmount
	) {
		const quoteDetailsInAmountNum = convertToNumber(
			new BN(quoteDetails.inAmount),
			swapFromMarket.precision
		);
		const quoteDetailsOutAmountNum = convertToNumber(
			new BN(quoteDetails.outAmount),
			swapToMarket.precision
		);

		const correctMints =
			quoteDetails.inputMint === swapFromMarket.mint.toString() &&
			quoteDetails.outputMint === swapToMarket.mint.toString();

		const correctMode = isExactIn
			? quoteDetails.swapMode === 'ExactIn'
			: quoteDetails.swapMode === 'ExactOut';

		const correctAmounts = isExactIn
			? parseFloat(swapFromAmount) === quoteDetailsInAmountNum
			: parseFloat(swapToAmount) === quoteDetailsOutAmountNum;

		marketsMatchQuote = correctMints && correctMode && correctAmounts;
	}

	const isButtonDisabled =
		isLoading ||
		!marketsMatchQuote ||
		!quoteDetails ||
		!enableVersionedTx ||
		isApprovingTxn ||
		isInsufficientBalance ||
		isGeoblocked ||
		!!quoteDetails?.error ||
		willExceedMaxSpotBalance;

	useDebounce(
		() => {
			getRoute();
		},
		300,
		[
			desiredSwapAmountNum,
			swapFromMarket,
			swapToMarket,
			slippageBps,
			directRouteSwaps,
			isExactIn,
			additionalAccountsBuffer,
		]
	);

	// sync swap market indexes with their refs
	useEffect(() => {
		swapFromMarketIndexRef.current = swapFromMarket.marketIndex;
		swapToMarketIndexRef.current = swapToMarket.marketIndex;
		isApprovingTxnRef.current = isApprovingTxn;
		getRouteRef.current = getRoute;
	}, [
		swapFromMarket.marketIndex,
		swapToMarket.marketIndex,
		isApprovingTxn,
		getRoute,
	]);

	// listen for successful swap event
	useEffect(() => {
		appEventEmitter.on('successfulSwap', (swapRecordEvent) => {
			// check if the successful swap event is for the currently transacting swap
			if (
				swapFromMarketIndexRef.current === swapRecordEvent.inMarketIndex &&
				swapToMarketIndexRef.current === swapRecordEvent.outMarketIndex &&
				isApprovingTxnRef.current
			) {
				setIsApprovingTxn(false);
				getRouteRef.current();
			}
		});
	}, [appEventEmitter]);

	useEffect(() => {
		if (driftClientIsReady && currentAccount?.client.isSubscribed) {
			setMaxSwapAmountDetails(
				currentAccount?.client?.getMaxSwapAmount({
					inMarketIndex: swapFromMarket.marketIndex,
					outMarketIndex: swapToMarket.marketIndex,
				})
			);
		}
	}, [
		driftClientIsReady,
		swapFromMarket.marketIndex,
		swapToMarket.marketIndex,
		!!currentAccount?.client,
		fromTokenBalance.toNum(),
	]);

	// refresh optimal route every 30s
	useEffect(() => {
		let interval;
		if (quoteDetails) {
			interval = setInterval(() => getRoute(), 30000);
		}

		return () => clearInterval(interval);
	}, [quoteDetails]);

	// remove other swap amount when route is loading
	useEffect(() => {
		if (isLoading) {
			getSwapConfig(isExactIn).setOtherAmount('');
		}
	}, [isLoading, isExactIn]);

	// update other swap amount when route details change
	useDebounce(
		() => {
			const { otherMarket, setOtherAmount } = getSwapConfig(isExactIn);

			if (!quoteDetails) {
				setOtherAmount('');
				return;
			}

			const amountStr = BigNum.from(
				isExactIn ? quoteDetails.outAmount : quoteDetails.inAmount,
				otherMarket.precisionExp
			).printShort();

			setOtherAmount(amountStr);
		},
		300,
		[quoteDetails, swapFromMarket, swapToMarket, isExactIn]
	);

	function calcMaxSwapSize() {
		let maxLeveragedSwapSize = BigNum.from(
			maxSwapAmountDetails?.inAmount,
			swapFromMarket.precisionExp
		);

		// prevent race condition where either variable is not using the correct market yet
		if (!fromTokenBalance.precision.eq(maxLeveragedSwapSize.precision)) {
			return BigNum.zero();
		}

		if (allowMargin) {
			// allow a 1% buffer if max size is leveraged, since numbers are constantly updated
			maxLeveragedSwapSize = maxLeveragedSwapSize
				.mul(new BN(99))
				.div(new BN(100));
		}

		let maxSwapSize: BigNum;
		if (allowMargin) {
			maxSwapSize = maxLeveragedSwapSize;
		} else {
			if (fromTokenBalance.gt(maxLeveragedSwapSize)) {
				// max swap size should never be greater than the max leveraged size
				maxSwapSize = maxLeveragedSwapSize;
			} else {
				maxSwapSize = fromTokenBalance.isNeg()
					? BigNum.zero(swapFromMarket.precisionExp)
					: fromTokenBalance;
			}
		}

		return maxSwapSize;
	}

	async function getRoute() {
		setIsLoading(true);
		try {
			if (desiredSwapAmountNum > 0) {
				const jupiterClient = new JupiterClient({
					connection: connection,
				});

				const accountsToUse = UI_UTILS.getAccountsInUse(currentAccount?.client);

				// Use more max accounts for pyusd and dsol, the problem cases
				const useBiggerMaxAccounts = biggerMaxAccountsMarketIndexes.some(
					(index) =>
						swapFromMarket.marketIndex === index ||
						swapToMarket.marketIndex === index
				);

				const maxAccounts = useBiggerMaxAccounts
					? DEFAULT_MAX_ACCOUNTS_BIG -
					  Math.min(5, additionalAccountsBuffer) -
					  accountsToUse
					: DEFAULT_MAX_ACCOUNTS - additionalAccountsBuffer - accountsToUse;

				const quote = await jupiterClient.getQuote({
					inputMint: swapFromMarket.mint,
					outputMint: swapToMarket.mint,
					amount: desiredSwapAmount.val,
					slippageBps,
					swapMode,
					maxAccounts,
					onlyDirectRoutes: directRouteSwaps,
				});
				setQuoteDetails(quote);

				if (quote.error) {
					console.error('Error in jupiter quote response', quote);
					if (
						quote.errorCode === 'ROUTE_PLAN_DOES_NOT_CONSUME_ALL_THE_AMOUNT'
					) {
						// Try a low number for the max accounts buffer if we hit this error
						if (additionalAccountsBuffer > 5 && !useBiggerMaxAccounts) {
							setAdditionalAccountsBuffer(5);
						}
					}
				}

				const swapPrice =
					(+quote.outAmount / +quote.inAmount) *
					10 **
						(swapFromMarket.precisionExp.toNumber() -
							swapToMarket.precisionExp.toNumber());
				setSwapPrice(swapPrice);
			} else {
				setQuoteDetails(null);
				setSwapPrice(0);
			}
		} catch (err) {
			console.error('Error fetching jupiter routes', err);
			setQuoteDetails(null);
			setSwapPrice(0);
		} finally {
			setIsLoading(false);
		}
	}

	function getSwapConfig(isExactIn: boolean) {
		if (isExactIn) {
			return {
				exactMarket: swapFromMarket,
				otherMarket: swapToMarket,
				exactAmount: swapFromAmount,
				setExactAmount: setSwapFromAmount,
				setOtherAmount: setSwapToAmount,
			};
		} else {
			return {
				exactMarket: swapToMarket,
				otherMarket: swapFromMarket,
				exactAmount: swapToAmount,
				setExactAmount: setSwapToAmount,
				setOtherAmount: setSwapFromAmount,
			};
		}
	}

	const resetForm = () => {
		setSwapFromAmount('');
		setSwapToAmount('');
		setQuoteDetails(null);
		setSwapPrice(0);
	};

	const enableVersionedTransaction = () => {
		setSavedSettings({
			...savedSettings,
			enableVersionedTx: true,
		});
		notify({
			type: 'success',
			message: 'Versioned transaction enabled',
			description: 'You can now use swaps.',
		});
	};

	const handleSwap = async () => {
		if (isInsufficientBalance) {
			notify({
				type: 'error',
				description: 'You cannot swap more than your balance',
				message: 'Swap failed',
			});

			return;
		}

		if (!quoteDetails) {
			console.error('Route details not provided for swap');
			return;
		}

		// hardcode 2 seconds disable after submit
		setIsApprovingTxn(true);
		setTimeout(() => {
			setIsApprovingTxn(false);
		}, 3000);

		try {
			if (savedSettings.showTradeConfirmation) {
				// lock in swap details to show in the modal
				setStore((s) => {
					s.swap = {
						fromMarketIndex: swapFromMarket.marketIndex,
						toMarketIndex: swapToMarket.marketIndex,
						swapDetails: {
							userKey: currentUserKey,
							amount: desiredSwapAmount.val,
							fromMarketIndex: swapFromMarket.marketIndex,
							toMarketIndex: swapToMarket.marketIndex,
							swapMode,
							quote: quoteDetails,
							onlyDirectRoutes: directRouteSwaps,
							slippageBps,
						},
					};
				});
				actions.showModal('showSwapConfirmationModal', true);
				return;
			}

			await actions.swap(
				currentUserKey,
				desiredSwapAmount.val,
				swapFromMarket.marketIndex,
				swapToMarket.marketIndex,
				swapMode,
				quoteDetails,
				directRouteSwaps,
				slippageBps
			);
		} catch (err) {
			console.error('Error swapping', err);
		} finally {
			getRoute();
		}
	};

	const calcOtherAmountAtMaxSlippage = () => {
		const otherAmount = +(
			(isExactIn ? quoteDetails?.outAmount : quoteDetails?.inAmount) ?? '0'
		);
		const slippageToApply = isExactIn ? 1 - slippage / 100 : 1 + slippage / 100;

		const otherAmountAtMaxSlippage = (
			(otherAmount * slippageToApply) /
			(getSwapConfig(isExactIn).otherMarket.precision?.toNumber() ?? 1)
		).toFixed(
			getSwapConfig(isExactIn).otherMarket.precisionExp?.toNumber() ?? 0
		);

		return otherAmountAtMaxSlippage;
	};

	return {
		enableVersionedTx,
		enableVersionedTransaction,
		swapMode,
		setSwapMode,
		swapFromAmount,
		setSwapFromAmount,
		swapToAmount,
		setSwapToAmount,
		inputNumericAmount,
		getSwapConfig,
		quoteDetails,
		swapPrice,
		resetForm,
		handleSwap,
		isLoading,
		setIsLoading,
		isButtonDisabled,
		maxLeverageAllowed,
		maxSwapSize,
		currentLeverage,
		isInsufficientBalance,
		calcOtherAmountAtMaxSlippage,
		isGeoblocked,
		borrowAmount,
		borrowToBeRepaid,
		isApprovingTxn,
		hasMaxSpotBalance: willExceedMaxSpotBalance,
	};
}
