import React from 'react';

const PreLaunchMarketIcon = (props: {
	size?: number;
	className?: string;
	color?: string;
}) => {
	return (
		<svg
			width={props.size || 16}
			height={props.size || 16}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={props.className}
		>
			<path
				d="M6.66637 12.4167H9.33303L10.707 8.01941C10.9002 7.40166 10.9406 6.74625 10.8247 6.10946C10.7088 5.47267 10.4401 4.87351 10.0417 4.36341L8.31503 2.15341C8.27763 2.10548 8.22978 2.06671 8.17514 2.04004C8.1205 2.01337 8.0605 1.99951 7.9997 1.99951C7.9389 1.99951 7.8789 2.01337 7.82426 2.04004C7.76962 2.06671 7.72178 2.10548 7.68437 2.15341L5.9577 4.36341C5.55928 4.87351 5.2906 5.47267 5.17471 6.10946C5.05883 6.74625 5.0992 7.40166 5.29237 8.01941L6.66637 12.4167ZM6.66637 12.4167L4.66637 13.7501C4.66637 13.7501 3.33304 11.0834 5.66637 9.41675M10.6204 9.41675C12.9537 11.0834 11.6204 13.7501 11.6204 13.7501L9.62037 12.4167"
				stroke={props.color || 'currentColor'}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M8.00008 8.41667C7.64646 8.41667 7.30732 8.27619 7.05727 8.02614C6.80722 7.77609 6.66675 7.43696 6.66675 7.08333C6.66675 6.72971 6.80722 6.39057 7.05727 6.14052C7.30732 5.89048 7.64646 5.75 8.00008 5.75C8.3537 5.75 8.69284 5.89048 8.94289 6.14052C9.19294 6.39057 9.33341 6.72971 9.33341 7.08333C9.33341 7.43696 9.19294 7.77609 8.94289 8.02614C8.69284 8.27619 8.3537 8.41667 8.00008 8.41667Z"
				stroke={props.color || 'currentColor'}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default PreLaunchMarketIcon;
