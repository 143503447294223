import React from 'react';
import { twMerge } from 'tailwind-merge';
import SemanticChip from '../Utils/SemanticChip';
import GradientBackgroundBox from '../Utils/GradientBackgroundBox';
import { IconProps } from '@drift-labs/icons';
import { JSX } from 'react';

export type TabbedNavigationProps = {
	onChange: (selection: string) => void;
	currentSelection: string;
	options: {
		value: string;
		label: string;
		numberIndicator?: number;
		alert?: boolean;
		onClick?: () => void;
		icon?: (allProps: IconProps) => JSX.Element;
	}[];
	className?: string;
	tabClassName?: string;
	underlineClassName?: string;
	gradient?: boolean;
	fadeOutBorder?: boolean;
	bgHighlightSelected?: boolean;
};

const NavigationTab = (props: {
	onClick: () => void;
	label: string;
	selected: boolean;
	numberIndicator?: number;
	alert?: boolean;
	className?: string;
	gradient?: boolean; // gradient label when tab is selected
	underlineClassName?: string;
	bgHighlightSelected?: boolean;
	Icon?: (allProps: IconProps) => JSX.Element;
}) => {
	return (
		<div
			className={twMerge(
				`py-2 px-3
				 flex items-center relative`,
				`hover:cursor-pointer hover:bg-container-bg-hover`,
				props.selected ? 'text-text-emphasis' : 'text-text-label',
				props.bgHighlightSelected &&
					props.selected &&
					'bg-container-bg-selected',
				props.className
			)}
			onClick={props.onClick}
		>
			{props.Icon ? (
				<props.Icon
					color={props.selected ? 'url(#icon-gradient)' : undefined}
					className="mr-[6px]"
					size={21}
				/>
			) : null}

			<span
				className={twMerge(
					'flex items-center justify-center',
					props.gradient && props.selected && 'text-gradient-1'
				)}
			>
				{props.label}
			</span>

			{props.numberIndicator ? (
				<span
					className="inline-block px-1 ml-2 font-numeral text-chip-default-text bg-chip-default-bg"
					style={{
						fontSize: '10px',
						borderRadius: '3px',
					}}
				>
					<span className="mt-1">{props.numberIndicator}</span>
				</span>
			) : props.alert ? (
				<SemanticChip className="ml-2 mb-0.5" status="red" />
			) : undefined}

			{props.selected && (
				<GradientBackgroundBox
					className={twMerge(
						'absolute left-0 bottom-0 h-0.5 w-full z-10',
						props.underlineClassName
					)}
				/>
			)}
		</div>
	);
};

const UnderlinedTabs = ({
	fadeOutBorder = true,
	...props
}: TabbedNavigationProps) => {
	const onSelect = (selection: string) => {
		props.onChange(selection);
	};

	return (
		<div className="relative">
			<div className={twMerge(`flex`, props.className)}>
				{props.options.map((opt) => (
					<NavigationTab
						key={opt.value}
						onClick={() => {
							onSelect(opt.value);
						}}
						label={opt.label}
						selected={props.currentSelection === opt.value}
						numberIndicator={opt.numberIndicator}
						className={props.tabClassName}
						gradient={props.gradient}
						underlineClassName={props.underlineClassName}
						bgHighlightSelected={props.bgHighlightSelected}
						Icon={opt.icon}
					/>
				))}
			</div>
			<div
				className="w-full absolute inset-x-0 bottom-0 h-[1px] z-0"
				style={{
					background: fadeOutBorder
						? 'linear-gradient(to left, rgba(0,0,0,0), var(--darkBlue-50))'
						: 'var(--stroke-secondary)',
				}}
			/>
		</div>
	);
};

export default UnderlinedTabs;
