'use client';

import { useMemo } from 'react';
import { STABLE_COIN_SYMBOLS } from 'src/constants/constants';
import useSWR from 'swr';
import { PublicKey } from '@drift-labs/sdk';
import { GET_API_ROUTE } from 'src/constants/api';
import { HistoricalTokenPriceDuration } from 'src/utils/graph';

// interface CoinDetails {
// 	id: string;
// 	symbol: string;
// 	name: string;
// }

export interface SimpleTokenData {
	[id: string]: {
		usd_24h_vol: number;
		usd_24h_change: number;
		usd: number;
	};
}

type OpenHighLowClose = [
	ms: number,
	open: number,
	high: number,
	low: number,
	close: number
];

export interface MarketChartData {
	prices: [ms: number, price: number][];
}

interface JupiterPriceApi {
	data: {
		[key: string]: {
			id: string;
			mintSymbol: string;
			vsToken: string;
			vsTokenSymbol: string;
			price: number;
		};
	};
}

const swrConfig = {
	errorRetryInterval: 20_000, // 20 seconds
	revalidateOnFocus: false,
	dedupingInterval: 60_000, // 60 seconds
};

const DEFAULT_TOKEN_PAIR_DATA = {
	tokenPairPrice: 0,
	low24h: 0,
	high24h: 0,
	historicalPrices: [],
	isLoading: false,
	fromTokenPrice: 0,
	toTokenPrice: 0,
};

// CoinGecko IDs for frequently used tokens; symbols are Drift spot market config symbols
const FREQUENTLY_USED_TOKEN_SYMBOLS = [
	{ symbol: 'sol', id: 'solana' },
	{ symbol: 'usdc', id: 'usd-coin' },
	{ symbol: 'msol', id: 'msol' },
	{ symbol: 'wbtc', id: 'wrapped-btc-wormhole' },
	{ symbol: 'usdt', id: 'tether' },
	{ symbol: 'weth', id: 'weth' },
	{ symbol: 'jitosol', id: 'jito-staked-sol' },
	{ symbol: 'pyth', id: 'pyth-network' },
	{ symbol: 'bsol', id: 'blazestake-staked-sol' },
	{ symbol: 'jto', id: 'jito-governance-token' },
	{ symbol: 'jup', id: 'jupiter-exchange-solana' },
	{ symbol: 'wif', id: 'dogwifcoin' },
	{ symbol: 'rndr', id: 'render-token' },
	{ symbol: 'w', id: 'wormhole' },
	{ symbol: 'tnsr', id: 'tensor' },
	{ symbol: 'drift', id: 'drift-protocol' },
	{ symbol: 'dsol', id: 'drift-staked-sol' },
	{ symbol: 'inf', id: 'socean-staked-sol' },
	{ symbol: 'usdy', id: 'ondo-us-dollar-yield' },
	{ symbol: 'jlp', id: 'jupiter-perpetuals-liquidity-provider-token' },
	{ symbol: 'popcat', id: 'popcat' },
	{ symbol: 'cloud', id: 'sanctum-2' },
	{ symbol: 'pyusd', id: 'paypal-usd' },
	{ symbol: 'usde', id: 'ethena-usde' },
	{ symbol: 'susde', id: 'ethena-staked-usde' },
	{ symbol: 'bnsol', id: 'binance-staked-sol' },
	{ symbol: 'mother', id: 'mother-iggy' },
	{ symbol: 'cbbtc', id: 'coinbase-wrapped-btc' },
];

const JUPITER_PRICE_API = 'https://price.jup.ag/v6/price';

const useCoingeckoTokenPairIds = (
	fromTokenSymbol: string,
	toTokenSymbol: string
) => {
	// can be used if more dynamic tokens are added
	// const { data: coinsList, error: coinListError } = useSWR<CoinDetails[]>(
	// 	`${COINGECKO_API_URL}/coins/list`,
	// 	{
	// 		fallbackData: [],
	// 		...swrConfig,
	// 	}
	// );

	const fromTokenId = FREQUENTLY_USED_TOKEN_SYMBOLS.find(
		(coin) => coin.symbol.toLowerCase() === fromTokenSymbol?.toLowerCase()
	)?.id;
	const toTokenId = FREQUENTLY_USED_TOKEN_SYMBOLS.find(
		(coin) => coin.symbol.toLowerCase() === toTokenSymbol?.toLowerCase()
	)?.id;

	return {
		fromTokenId,
		toTokenId,
	};
};

// Uses CoinGecko API to fetch token prices.
// If either of the token involved is USDC, the price will be denominated in USDC
export const useTokenPairData = (
	fromTokenSymbol: string,
	toTokenSymbol: string
) => {
	const { fromTokenId, toTokenId } = useCoingeckoTokenPairIds(
		fromTokenSymbol,
		toTokenSymbol
	);

	// fetch tokens prices
	const {
		data: tokensData,
		error: tokensDataError,
		isLoading: isTokenPriceLoading,
	} = useSWR<SimpleTokenData>(
		fromTokenId && toTokenId
			? `${GET_API_ROUTE('coingecko-simple-price')}?${new URLSearchParams({
					tokens: [fromTokenId, toTokenId].join(','),
			  })}`
			: null,
		swrConfig
	);

	const fromTokenData = tokensData?.[fromTokenId];
	const toTokenData = tokensData?.[toTokenId];

	// fetch open, high, low, close prices for the last 24 hours at 30 mins intervals
	const {
		data: from24hOhlc,
		error: fromOhlcError,
		isLoading: isFromOhlcLoading,
	} = useSWR<OpenHighLowClose[]>(
		fromTokenId &&
			`${GET_API_ROUTE('coingecko-ohlc')}?${new URLSearchParams({
				tokenId: fromTokenId,
			})}`,
		swrConfig
	);
	const {
		data: to24hOhlc,
		error: toOhlcError,
		isLoading: isToOhlcLoading,
	} = useSWR<OpenHighLowClose[]>(
		toTokenId &&
			`${GET_API_ROUTE('coingecko-ohlc')}?${new URLSearchParams({
				tokenId: toTokenId,
			})}`,
		swrConfig
	);

	const isLoading = isTokenPriceLoading || isFromOhlcLoading || isToOhlcLoading;

	// denominate by stable coin if fromToken is stable coin
	const tokenPairPrice = STABLE_COIN_SYMBOLS.includes(
		fromTokenSymbol?.toLowerCase()
	)
		? (toTokenData?.usd ?? 0) / (fromTokenData?.usd || 1)
		: (fromTokenData?.usd ?? 0) / (toTokenData?.usd || 1);

	const low24h =
		from24hOhlc?.reduce((min, fromOhlc) => {
			const timeInterval = fromOhlc[0];
			const fromTokenLowPrice = fromOhlc[3];

			const toOhlc = to24hOhlc?.find((toOhlc) => toOhlc[0] === timeInterval);
			const toTokenLowPrice = toOhlc?.[3];

			const timeIntervalLow = STABLE_COIN_SYMBOLS.includes(
				fromTokenSymbol?.toLowerCase()
			)
				? (toTokenLowPrice ?? 0) / (fromTokenLowPrice || 1)
				: (fromTokenLowPrice ?? 0) / (toTokenLowPrice || 1);

			return Math.min(min, timeIntervalLow);
		}, Infinity) ?? 0;

	const high24h =
		from24hOhlc?.reduce((max, fromOhlc) => {
			const timeInterval = fromOhlc[0];
			const fromTokenHighPrice = fromOhlc[2];

			const toOhlc = to24hOhlc?.find((toOhlc) => toOhlc[0] === timeInterval);
			const toTokenHighPrice = toOhlc?.[2];

			const timeIntervalHigh = STABLE_COIN_SYMBOLS.includes(
				fromTokenSymbol?.toLowerCase()
			)
				? (toTokenHighPrice ?? 0) / (fromTokenHighPrice || 1)
				: (fromTokenHighPrice ?? 0) / (toTokenHighPrice || 1);

			return Math.max(max, timeIntervalHigh);
		}, 0) ?? 0;

	if (!fromTokenId || !toTokenId) {
		return DEFAULT_TOKEN_PAIR_DATA;
	}

	// default data if any error occurs
	if (tokensDataError || fromOhlcError || toOhlcError) {
		console.error(tokensDataError || fromOhlcError || toOhlcError);
		return DEFAULT_TOKEN_PAIR_DATA;
	}

	return {
		tokenPairPrice,
		low24h,
		high24h,
		isLoading,
		fromTokenPrice: fromTokenData?.usd ?? 0,
		toTokenPrice: toTokenData?.usd ?? 0,
	};
};

export const useTokenJupPriceData = (mint: PublicKey | string) => {
	const { data, isLoading } = useSWR<JupiterPriceApi>(
		mint ? `${JUPITER_PRICE_API}?ids=${mint.toString()}` : null
	);

	const price = data?.data[mint.toString()]?.price ?? 0;

	return {
		price,
		isLoading,
	};
};
export const useJupiterTokenPairData = (
	swapFromMarket: {
		mint: PublicKey | string;
		symbol?: string;
	},
	swapToMarket: {
		mint: PublicKey | string;
	}
) => {
	const { data } = useSWR<JupiterPriceApi>(
		`${JUPITER_PRICE_API}?ids=${
			swapFromMarket.mint
		}&vsToken=${swapToMarket.mint.toString()}`
	);

	const mintString = useMemo(() => {
		return swapFromMarket?.mint?.toString();
	}, [swapFromMarket?.mint]);

	let price = data?.data[mintString]?.price ?? 0;

	price =
		price &&
		swapFromMarket.symbol &&
		STABLE_COIN_SYMBOLS.includes(swapFromMarket.symbol)
			? 1 / (price || 1)
			: price;

	return price;
};

/**
 * Fetches both tokens' historical prices and returns the denominated prices.
 * It will denominate the prices by USDC/T if USDC/T is one of the tokens.
 * 1 day = ~5 mins interval between prices
 * > 1 day = ~1 hour interval between prices
 */
export const useTokenPairHistoricalPrices = (
	fromTokenSymbol: string,
	toTokenSymbol: string,
	duration: HistoricalTokenPriceDuration
) => {
	const { fromTokenId, toTokenId } = useCoingeckoTokenPairIds(
		fromTokenSymbol,
		toTokenSymbol
	);

	// fetch token historical prices
	const { data: fromTokenHistoricalPrices, error: fromHistoricalPricesError } =
		useSWR<MarketChartData>(
			fromTokenId
				? `${GET_API_ROUTE('coingecko-history')}?${new URLSearchParams({
						tokenId: fromTokenId,
						duration,
				  })}`
				: null,
			swrConfig
		);
	const { data: toTokenHistoricalPrices, error: toHistoricalPricesError } =
		useSWR<MarketChartData>(
			toTokenId
				? `${GET_API_ROUTE('coingecko-history')}?${new URLSearchParams({
						tokenId: toTokenId,
						duration,
				  })}`
				: null,
			swrConfig
		);

	// fetch current price
	const { data: tokensData, error: tokensDataError } = useSWR<SimpleTokenData>(
		fromTokenId && toTokenId
			? `${GET_API_ROUTE('coingecko-simple-price')}?${new URLSearchParams({
					tokens: [fromTokenId, toTokenId].join(','),
			  })}`
			: null,
		swrConfig
	);

	// use current price as last historical price
	const fromLastIndex = (fromTokenHistoricalPrices?.prices?.length ?? 0) - 1;
	const toLastIndex = (toTokenHistoricalPrices?.prices?.length ?? 0) - 1;
	if (fromTokenHistoricalPrices?.prices[fromLastIndex]) {
		fromTokenHistoricalPrices.prices[fromLastIndex] = [
			fromTokenHistoricalPrices?.prices[fromLastIndex][0],
			tokensData?.[fromTokenId]?.usd ?? 0,
		];
	}
	if (toTokenHistoricalPrices?.prices[toLastIndex]) {
		toTokenHistoricalPrices.prices[toLastIndex] = [
			toTokenHistoricalPrices?.prices[toLastIndex][0],
			tokensData?.[toTokenId]?.usd ?? 0,
		];
	}

	const interval = duration === HistoricalTokenPriceDuration.ONE_DAY ? 5 : 60;

	const roundedFromTokenHistoricalPrices =
		fromTokenHistoricalPrices?.prices.map(([ms, price]) => ({
			ms: Math.round(ms / (interval * 60 * 1000)) * (interval * 60 * 1000),
			price,
		})) ?? [];
	const uniqueFromTokenHistoricalPrices =
		roundedFromTokenHistoricalPrices.filter(({ ms }, index) => {
			if (index === 0) return true;
			return ms !== roundedFromTokenHistoricalPrices[index - 1].ms;
		});

	const roundedToTokenHistoricalPrices =
		toTokenHistoricalPrices?.prices.map(([ms, price]) => ({
			ms: Math.round(ms / (interval * 60 * 1000)) * (interval * 60 * 1000),
			price,
		})) ?? [];
	const uniqueToTokenHistoricalPrices = roundedToTokenHistoricalPrices.filter(
		({ ms }, index) => {
			if (index === 0) return true;
			return ms !== roundedToTokenHistoricalPrices[index - 1].ms;
		}
	);

	const historicalPrices = useMemo(
		() =>
			uniqueFromTokenHistoricalPrices.reduce((acc, price) => {
				const toTokenPrice = uniqueToTokenHistoricalPrices.find(
					(toPrice) => toPrice.ms === price.ms
				)?.price;

				if (!toTokenPrice) return acc;

				let tokenPairPrice = price.price / (toTokenPrice || 1);

				// always denominate by USDC if it is one of the tokens
				if (STABLE_COIN_SYMBOLS.includes(fromTokenSymbol.toLowerCase())) {
					tokenPairPrice = 1 / tokenPairPrice;
				}

				return [...acc, { ms: price.ms, price: tokenPairPrice }];
			}, []),
		[fromTokenHistoricalPrices, toTokenHistoricalPrices, tokensData]
	);

	if (!fromTokenId || !toTokenId) {
		return [];
	}

	// default data if any error occurs
	if (fromHistoricalPricesError || toHistoricalPricesError || tokensDataError) {
		console.error(
			fromHistoricalPricesError || toHistoricalPricesError || tokensDataError
		);
		return [];
	}

	return historicalPrices;
};

export const useTokenSimplePrice = (...coingeckoId: string[]) => {
	const { data, error, isLoading } = useSWR<SimpleTokenData>(
		`${GET_API_ROUTE('coingecko-simple-price')}?${new URLSearchParams({
			tokens: coingeckoId.join(','),
		})}`
	);

	return {
		data: data ?? {},
		error,
		isLoading,
	};
};
