const MAIN_API_PATH = '/api';

type ApiRoutes = {
	'coingecko-history': string;
	'coingecko-ohlc': string;
	'coingecko-simple-price': string;
	'bets-liquidity': string;
	'track-bet-magic-eden': string;
	vaults: string;
	'vault-snapshots': string;
	'vault-depositor': string;
};

const API_ROUTES: ApiRoutes = {
	'coingecko-history': '/coingecko/history',
	'coingecko-ohlc': '/coingecko/ohlc',
	'coingecko-simple-price': '/coingecko/simple-price',
	'bets-liquidity': '/bets/liquidity',
	'track-bet-magic-eden': '/magic-eden/track',
	vaults: '/vaults',
	'vault-snapshots': '/vaults/vault-snapshots',
	'vault-depositor': '/vaults/vault-depositor',
};

export const GET_API_ROUTE = (key: keyof ApiRoutes) => {
	return `${MAIN_API_PATH}${API_ROUTES[key]}`;
};
