'use client';

import useDriftAccountStore, {
	AccountData,
} from 'src/stores/useDriftAccountsStore';
import useWalletIsConnected from './useWalletIsConnected';

const DEFAULT_STATE: AccountData = undefined;

const useTargetAccountData = (userKey?: string): AccountData => {
	const currentUserKey = useDriftAccountStore((s) => s.currentUserKey);
	const isConnected = useWalletIsConnected();

	const currentAccounts = useDriftAccountStore((s) => s.accounts);

	const currentAccount = currentAccounts[userKey ?? currentUserKey];

	const accountData =
		isConnected && currentAccount ? currentAccount : DEFAULT_STATE;

	return accountData;
};

export default useTargetAccountData;
