'use client';

import { useState } from 'react';
import useInterval from '../useInterval';

const useDebouncedValue = <T>(valueGetter: () => T, intervalMs = 500) => {
	const [debouncedValue, setDebouncedValue] = useState(valueGetter());

	useInterval(() => {
		setDebouncedValue(valueGetter());
	}, intervalMs);

	return debouncedValue;
};

export default useDebouncedValue;
